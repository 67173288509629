import { theme } from '@buggy/shared'
import Link from 'next/link'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { H6 } from '@/components/common/typography'
import { Icon } from '@/components/custom-icon'
import { WorkspaceDropdown } from '@/components/header/workspace-dropdown'
import { Page } from '@/hooks/use-authorization/constants'
import { usePermissions } from '@/hooks/use-permissions'
import { useIntl } from '@/i18n/hooks/use-intl'

/**
 * @deprecated used by error page
 */
export const HeaderItemGroupWithWorkspace: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const { withGuestPermission } = usePermissions()

  const fontSize = '24px'

  return (
    <HeaderItemGroupWrapper>
      {!withGuestPermission && (
        <HeaderItemWrapper>
          <Link href={Page.CollectionsKol}>
            <Icon
              style={{ fontSize, color: theme.colors.text.primary }}
              type='elIconCheckListThin'
            />
            <H6>{formatMessage({ id: 'general:navbar_project_text' })}</H6>
          </Link>
        </HeaderItemWrapper>
      )}
      <WorkspaceDropdown />
    </HeaderItemGroupWrapper>
  )
}

const HeaderItemGroupWrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;

    > * {
      :not(:last-child) {
        margin-right: 41px;
      }
    }
  }
`

const HeaderItemWrapper = styled.div`
  cursor: pointer;

  ${H6} {
    display: inline-block;
    margin-left: 8px;
  }

  .anticon {
    color: #3e3d3d;
  }

  .bullhorn {
    svg {
      padding-top: 5px;
    }
  }

  &:hover {
    color: ${({ theme }): string => theme.colors.brand.primaryHover};
  }
`
