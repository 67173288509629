import { Cascader } from 'antd'
import { type FunctionComponent, useMemo } from 'react'
import styled from 'styled-components'
import CustomizedTagSearch from '@/components/search/filters/customized-tag-search'
import useCustomizedTag from '@/hooks/use-customized-tag'
import { type CustomizedTag } from '@/types/schema/search'

interface CustomizedContentMultiModeProps {
  customizedTagsNames?: string
  onValuesChange: (customizedTagsNames?: string) => void
  onSearchChange?: (value: string) => void
  data: CustomizedTag[]
  isSearchable?: boolean
}

interface Option {
  value: string
  label: string
  children?: Option[]
}

/* 將 "level1/level2" 換換成 ["level1", "level1/level2"] */
const convertToHierarchicalTags = (category: string): string[] => {
  return category
    .split('/')
    .map((_, index, array) =>
      index === 0 ? array[0] : array.slice(0, index + 1).join('/'),
    )
}

const convertToDefaultTags = (
  customizedTagsNames: string,
): string[][] | undefined => {
  if (!customizedTagsNames) {
    return undefined
  }

  const categoryList = customizedTagsNames.split('|')

  return categoryList.map(convertToHierarchicalTags)
}

const CustomizedContentMultiMode: FunctionComponent<
  CustomizedContentMultiModeProps
> = ({
  data,
  customizedTagsNames = '',
  onValuesChange,
  onSearchChange,
  isSearchable = false,
}) => {
  const { getCustomizedTagLocalizedName } = useCustomizedTag()

  const selectedCustomizedTags = useMemo(
    () => convertToDefaultTags(customizedTagsNames),
    [customizedTagsNames],
  )

  const options = useMemo(() => {
    const convertToOption = (tag: CustomizedTag): Option => {
      const option: Option = {
        value: tag.customized_tag_name,
        label: getCustomizedTagLocalizedName(tag.customized_tag_name),
      }

      if (
        tag.sub_customized_tag_names &&
        tag.sub_customized_tag_names.length > 0
      ) {
        option.children = tag.sub_customized_tag_names.map((subTag) =>
          convertToOption(subTag),
        )
      }

      return option
    }

    return data.map((tag) => convertToOption(tag))
  }, [data, getCustomizedTagLocalizedName])

  const handleValuesChange = (value: string[][]): void =>
    onValuesChange(value?.map((v) => v[v.length - 1]).join('|'))

  const handleTagsChange = (tags: string[]): void =>
    onValuesChange(tags.join('|'))

  const displayTags = (selectedCustomizedTags ?? []).map(
    (tag) => tag[tag.length - 1],
  )

  return (
    <SelectAreaWrapper>
      {isSearchable && (
        <CustomizedTagSearch
          value={displayTags}
          onSearchChange={onSearchChange}
          onValueChange={handleTagsChange}
        />
      )}
      <StyledCascadePanel
        multiple
        notFoundContent={null}
        options={options}
        value={selectedCustomizedTags}
        onChange={handleValuesChange}
      />
    </SelectAreaWrapper>
  )
}

const SelectAreaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
`

const StyledCascadePanel = styled(Cascader.Panel)`
  width: 100%;
  border: none;
  height: 275px;

  .ant-cascader-menus {
    width: 100%;
    display: flex;

    .ant-cascader-menu {
      flex: 1 1 50%;
      max-width: 50%;
      box-sizing: border-box;

      &:first-child {
        border-inline-end: 1px solid
          ${({ theme }): string => theme.colors.border.border};
      }
    }
  }
`

export default CustomizedContentMultiMode
