import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { omit } from 'lodash-es'
import querystring from 'query-string'

export const paramsSerializer: NonNullable<
  FetchBaseQueryArgs['paramsSerializer']
> = ({ stringify, ...params }) => {
  if (stringify) {
    return querystring.stringify(omit(params, 'array_format'), {
      arrayFormat: params['array_format'],
    })
  } else {
    return new URLSearchParams(params).toString()
  }
}
