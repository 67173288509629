import { CurrencyCode } from '@buggy/shared'
import { Input, Radio, RadioChangeEvent, Slider } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import styled from 'styled-components'
import { useFetchUserStatusQuery } from '@/api/user-api'
import { RadioButton } from '@/components/common/radio-button'
import {
  mapKolQuotationCurrency,
  SupportedQuotationCurrency,
} from '@/hooks/use-kol-quotations-range'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Condition } from '@/types/api/search'
import { parseAsNumber } from '@/utils/parse-as-number'

enum RadioType {
  Unlimited = 'unlimited',
  Limit = 'limit',
}

const QUOTATION_CURRENCY_RANGE: Record<
  SupportedQuotationCurrency,
  { minimum: number; maximum: number }
> = {
  [CurrencyCode.TWD]: {
    minimum: 1,
    maximum: 1000000,
  },
  [CurrencyCode.JPY]: {
    minimum: 1,
    maximum: 4000000,
  },
  [CurrencyCode.USD]: {
    minimum: 1,
    maximum: 30000,
  },
}

interface QuotationRateSelectorContentProps {
  filterValues: Pick<Condition, 'minQuotationPrice' | 'maxQuotationPrice'>
  onValuesChange: (changedValues: Condition) => void
}

const QuotationRateSelectorContent: FunctionComponent<
  QuotationRateSelectorContentProps
> = ({ filterValues, onValuesChange }) => {
  const { formatMessage } = useIntl()

  const { data: userStatus } = useFetchUserStatusQuery()
  const quotationCurrency = mapKolQuotationCurrency(
    userStatus?.currentWorkspace?.currencyCode,
  )
  const { minimum, maximum } = QUOTATION_CURRENCY_RANGE[quotationCurrency]

  const [inputValue, setInputValue] = useState<{
    minQuotationPrice: number
    maxQuotationPrice: number
  }>({
    minQuotationPrice: filterValues.minQuotationPrice ?? minimum,
    maxQuotationPrice: filterValues.maxQuotationPrice ?? maximum,
  })
  const [radioType, setRadioType] = useState<RadioType>(
    minimum === filterValues.minQuotationPrice &&
      maximum === filterValues.maxQuotationPrice
      ? RadioType.Unlimited
      : RadioType.Limit,
  )

  const parseValidInputValue = (value: number): number => {
    if (value < minimum) {
      return minimum
    }

    if (value > maximum) {
      return maximum
    }

    return value
  }

  const isUnlimitedChanging = (
    fromValue: number,
    endValue: number,
  ): boolean => {
    return fromValue === minimum && endValue === maximum
  }

  const handleValueChange = (fromValue: number, endValue: number): void => {
    const isUnlimited = isUnlimitedChanging(fromValue, endValue)
    onValuesChange({
      minQuotationPrice: isUnlimited ? undefined : fromValue,
      maxQuotationPrice: isUnlimited ? undefined : endValue,
    })
  }

  const handleOnInputBlur = (): void => {
    const validFromValue = parseValidInputValue(inputValue.minQuotationPrice)
    const validEndValue = parseValidInputValue(inputValue.maxQuotationPrice)
    const isSwap = validFromValue > validEndValue
    const from = isSwap ? validEndValue : validFromValue
    const end = isSwap ? validFromValue : validEndValue
    const isUnlimited = isUnlimitedChanging(from, end)
    setInputValue({
      minQuotationPrice: from,
      maxQuotationPrice: end,
    })
    setRadioType(isUnlimited ? RadioType.Unlimited : RadioType.Limit)
    handleValueChange(from, end)
  }

  const resetDefaultValue: VoidFunction = () => {
    setInputValue({
      minQuotationPrice: minimum,
      maxQuotationPrice: maximum,
    })
    handleValueChange(minimum, maximum)
  }

  const handleTypeChange = (e: RadioChangeEvent): void => {
    setRadioType(e.target.value)
    if (e.target.value === RadioType.Unlimited) {
      resetDefaultValue()
    }
  }

  const handleSliderChangeComplete = ([from, end]: [number, number]): void => {
    setRadioType(
      isUnlimitedChanging(from, end) ? RadioType.Unlimited : RadioType.Limit,
    )
    handleValueChange(from, end)
  }

  // props: filterValues changed
  useDeepCompareEffect(() => {
    const from = filterValues.minQuotationPrice ?? minimum
    const end = filterValues.maxQuotationPrice ?? maximum
    const isUnlimited = isUnlimitedChanging(from, end)
    setInputValue({
      minQuotationPrice: from,
      maxQuotationPrice: end,
    })
    setRadioType(isUnlimited ? RadioType.Unlimited : RadioType.Limit)
  }, [filterValues])

  return (
    <ContentWrapper>
      <StyledRadioGroup value={radioType} onChange={handleTypeChange}>
        <RadioButton value={RadioType.Unlimited}>
          {formatMessage({ id: 'general:auto_tag_all' })}
        </RadioButton>
        <RadioButton value={RadioType.Limit}>
          {formatMessage({ id: 'kol:case_list_filter_specified_budget' })}
        </RadioButton>
      </StyledRadioGroup>
      <InputWrapper>
        <Input
          max={maximum}
          min={minimum}
          prefix='$'
          type='number'
          value={inputValue.minQuotationPrice}
          onBlur={handleOnInputBlur}
          onChange={(event): void => {
            setInputValue({
              minQuotationPrice: parseValidInputValue(
                parseAsNumber(event.target.value),
              ),
              maxQuotationPrice: inputValue.maxQuotationPrice,
            })
            handleValueChange(
              parseValidInputValue(parseAsNumber(event.target.value)),
              inputValue.maxQuotationPrice,
            )
          }}
        />
        <Hyphen> - </Hyphen>
        <Input
          max={maximum}
          min={minimum}
          prefix='$'
          type='number'
          value={inputValue.maxQuotationPrice}
          onBlur={handleOnInputBlur}
          onChange={(event): void => {
            setInputValue({
              minQuotationPrice: inputValue.minQuotationPrice,
              maxQuotationPrice: parseValidInputValue(
                parseAsNumber(event.target.value),
              ),
            })
            handleValueChange(
              inputValue.minQuotationPrice,
              parseValidInputValue(parseAsNumber(event.target.value)),
            )
          }}
        />
      </InputWrapper>
      <Slider
        range
        max={maximum}
        min={minimum}
        value={[inputValue.minQuotationPrice, inputValue.maxQuotationPrice]}
        onChange={([from, end]: [number, number]): void => {
          setInputValue({
            minQuotationPrice: from,
            maxQuotationPrice: end,
          })
        }}
        onChangeComplete={handleSliderChangeComplete}
      />
    </ContentWrapper>
  )
}

const Hyphen = styled.span`
  padding: 3px 2%;
`

const InputWrapper = styled.div`
  display: flex;
`

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-wrapper .ant-radio {
    top: unset;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-slider-track {
    background-color: ${({ theme }): string => theme.colors.brand.primary};
  }

  .ant-slider-handle {
    border-color: ${({ theme }): string => theme.colors.brand.primary};
  }

  ${StyledRadioGroup} {
    margin: 0 0 14px;
  }

  .ant-slider {
    margin: 14px 0;
  }
`
export default QuotationRateSelectorContent
