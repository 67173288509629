import { isString } from 'lodash-es'
import { z } from 'zod'
import { BaseQuery } from '@/utils/redux-toolkit'

export const apiVersionSchema = z.enum(['v2', 'v3'])
export type ApiVersion = z.infer<typeof apiVersionSchema>

export const defaultApiVersionBaseQueryWrapper: (
  baseQuery: BaseQuery,
) => BaseQuery = (baseQuery: BaseQuery) => (args, api, extraOptions) => {
  const onlyUrl = isString(args)
  const url = onlyUrl ? args : args.url
  const isVersionIncluded = apiVersionSchema.options.some((version) =>
    url.startsWith(`/${version}`),
  )

  if (isVersionIncluded) {
    return baseQuery(args, api, extraOptions)
  }

  const finalUrl = `/${apiVersionSchema.enum.v2}${url}`

  return baseQuery(
    onlyUrl ? finalUrl : { ...args, url: finalUrl },
    api,
    extraOptions,
  )
}
