import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'

const CountryCodeI18nMap: Record<string, I18nId> = {
  PK: 'search:PK',
  VA: 'search:VA',
  SV: 'search:SV',
  LA: 'search:LA',
  GE: 'search:GE',
  SA: 'search:SA',
  KG: 'search:KG',
  SK: 'search:SK',
  EC: 'search:EC',
  ZM: 'search:ZM',
  CG: 'search:CG',
  KI: 'search:KI',
  GB: 'search:GB',
  SE: 'search:SE',
  FJ: 'search:FJ',
  BW: 'search:BW',
  MZ: 'search:MZ',
  SD: 'search:SD',
  KN: 'search:KN',
  VC: 'search:VC',
  GH: 'search:GH',
  VU: 'search:VU',
  GQ: 'search:GQ',
  IE: 'search:IE',
  LS: 'search:LS',
  BA: 'search:BA',
  BN: 'search:BN',
  IQ: 'search:IQ',
  KZ: 'search:KZ',
  ES: 'search:ES',
  AL: 'search:AL',
  ST: 'search:ST',
  CN: 'search:CN',
  AU: 'search:AU',
  KY: 'search:KY',
  KH: 'search:KH',
  TM: 'search:TM',
  ML: 'search:ML',
  AI: 'search:AI',
  SG: 'search:SG',
  AR: 'search:AR',
  OM: 'search:OM',
  RS: 'search:RS',
  FO: 'search:FO',
  PY: 'search:PY',
  AM: 'search:AM',
  UZ: 'search:UZ',
  DO: 'search:DO',
  TZ: 'search:TZ',
  SN: 'search:SN',
  AF: 'search:AF',
  CI: 'search:CI',
  BR: 'search:BR',
  CO: 'search:CO',
  GN: 'search:GN',
  GD: 'search:GD',
  JM: 'search:JM',
  MW: 'search:MW',
  BB: 'search:BB',
  CA: 'search:CA',
  GT: 'search:GT',
  SM: 'search:SM',
  BM: 'search:BM',
  KP: 'search:KP',
  SO: 'search:SO',
  BE: 'search:BE',
  GR: 'search:GR',
  ET: 'search:ET',
  ID: 'search:ID',
  TR: 'search:TR',
  NA: 'search:NA',
  FR: 'search:FR',
  PL: 'search:PL',
  MU: 'search:MU',
  BH: 'search:BH',
  RO: 'search:RO',
  MN: 'search:MN',
  CM: 'search:CM',
  GY: 'search:GY',
  TC: 'search:TC',
  LI: 'search:LI',
  CZ: 'search:CZ',
  CH: 'search:CH',
  BS: 'search:BS',
  BG: 'search:BG',
  IO: 'search:IO',
  US: 'search:US',
  AT: 'search:AT',
  UY: 'search:UY',
  TL: 'search:TL',
  LV: 'search:LV',
  PE: 'search:PE',
  HR: 'search:HR',
  AD: 'search:AD',
  IN: 'search:IN',
  IT: 'search:IT',
  DM: 'search:DM',
  IM: 'search:IM',
  AZ: 'search:AZ',
  GW: 'search:GW',
  BF: 'search:BF',
  GI: 'search:GI',
  LB: 'search:LB',
  RW: 'search:RW',
  KM: 'search:KM',
  TH: 'search:TH',
  NG: 'search:NG',
  VG: 'search:VG',
  BI: 'search:BI',
  UA: 'search:UA',
  GS: 'search:GS',
  MR: 'search:MR',
  RU: 'search:RU',
  CY: 'search:CY',
  IS: 'search:IS',
  BY: 'search:BY',
  FI: 'search:FI',
  PW: 'search:PW',
  AG: 'search:AG',
  DJ: 'search:DJ',
  KR: 'search:KR',
  DZ: 'search:DZ',
  TN: 'search:TN',
  NE: 'search:NE',
  SL: 'search:SL',
  PN: 'search:PN',
  SC: 'search:SC',
  TW: 'search:TW',
  DK: 'search:DK',
  SR: 'search:SR',
  VN: 'search:VN',
  EE: 'search:EE',
  ME: 'search:ME',
  CF: 'search:CF',
  FK: 'search:FK',
  PH: 'search:PH',
  BD: 'search:BD',
  LY: 'search:LY',
  FM: 'search:FM',
  QA: 'search:QA',
  LR: 'search:LR',
  WS: 'search:WS',
  EG: 'search:EG',
  BZ: 'search:BZ',
  BO: 'search:BO',
  NI: 'search:NI',
  MT: 'search:MT',
  CR: 'search:CR',
  CD: 'search:CD',
  BJ: 'search:BJ',
  NL: 'search:NL',
  NZ: 'search:NZ',
  BT: 'search:BT',
  SY: 'search:SY',
  MK: 'search:MK',
  GM: 'search:GM',
  TK: 'search:TK',
  SH: 'search:SH',
  PT: 'search:PT',
  LK: 'search:LK',
  MC: 'search:MC',
  TV: 'search:TV',
  CL: 'search:CL',
  NO: 'search:NO',
  PG: 'search:PG',
  CK: 'search:CK',
  PA: 'search:PA',
  GL: 'search:GL',
  JO: 'search:JO',
  CU: 'search:CU',
  MX: 'search:MX',
  NP: 'search:NP',
  HT: 'search:HT',
  KE: 'search:KE',
  TG: 'search:TG',
  HN: 'search:HN',
  GG: 'search:GG',
  KW: 'search:KW',
  SI: 'search:SI',
  ZA: 'search:ZA',
  SZ: 'search:SZ',
  ZW: 'search:ZW',
  SB: 'search:SB',
  IL: 'search:IL',
  HU: 'search:HU',
  PS: 'search:PS',
  TJ: 'search:TJ',
  MM: 'search:MM',
  AE: 'search:AE',
  VE: 'search:VE',
  TD: 'search:TD',
  YE: 'search:YE',
  TT: 'search:TT',
  UG: 'search:UG',
  MG: 'search:MG',
  JP: 'search:JP',
  IR: 'search:IR',
  LU: 'search:LU',
  NR: 'search:NR',
  AO: 'search:AO',
  JE: 'search:JE',
  MH: 'search:MH',
  GA: 'search:GA',
  DE: 'search:DE',
  MA: 'search:MA',
  LC: 'search:LC',
  TO: 'search:TO',
  MY: 'search:MY',
  LT: 'search:LT',
  CV: 'search:CV',
  SS: 'search:SS',
  MV: 'search:MV',
  MD: 'search:MD',
} as const

type UseCountry = () => {
  value: keyof typeof CountryCodeI18nMap
  text: string
}[]

const useCountry: UseCountry = () => {
  const { formatMessage } = useIntl()

  return Object.entries(CountryCodeI18nMap).map(([code, i18nKey]) => ({
    value: code,
    text: formatMessage({ id: i18nKey }),
  }))
}

export default useCountry
