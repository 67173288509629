import { useMemo } from 'react'
import { v4 as uuid4 } from 'uuid'
import LocalStorageKeys from '@/constants/localstorage-keys'
import useLocalStorageSafely, {
  localStorageObserverMap,
} from '@/hooks/use-local-storage-safely'

type UseSearchId = () => {
  value?: string
  remove: () => void
}

// 不預期 UI 會拿 search id 去做事情，也不預期更改 search id 會導致任何元件 re-render
// 但目前有 effect 依賴 search id，故改完 localStorage 之後，要通知 subscribed 的元件
const updateSearchId = (): void => {
  const searchId = uuid4()
  localStorage.setItem(LocalStorageKeys.SearchId, searchId)
  ;(localStorageObserverMap[LocalStorageKeys.SearchId] ?? []).forEach(
    (observer) => observer(searchId),
  )
}
const removeSearchId = (): void => {
  localStorage.removeItem(LocalStorageKeys.SearchId)
  ;(localStorageObserverMap[LocalStorageKeys.SearchId] ?? []).forEach(
    (observer) => observer(null),
  )
}

const useSearchId: UseSearchId = () => {
  // 刻意不開放 update fn，不希望元件層有能力做這件事
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchId, _, removeSearchId] = useLocalStorageSafely(
    LocalStorageKeys.SearchId,
    '',
  )

  return useMemo(
    () => ({
      value: searchId,
      remove: removeSearchId,
    }),
    [removeSearchId, searchId],
  )
}

export default useSearchId
export { updateSearchId, removeSearchId }
