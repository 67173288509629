import { isEmpty } from 'lodash-es'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import env from '@/utils/env'

export const SafeNewTabLink: FunctionComponent<
  Omit<JSX.IntrinsicElements['a'], 'target'>
> = ({
  onClick: originalOnClick,
  ...props
}: Omit<JSX.IntrinsicElements['a'], 'target'>) => {
  const isRadarSite =
    props.href !== undefined &&
    (props.href.startsWith('/') ||
      props.href.includes(env.DEVELOPMENT_MODE ? 'localhost' : env.APP_URL))
  return (
    <a
      rel={isRadarSite ? undefined : 'noopener noreferrer'}
      target='_blank'
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        if (isEmpty(props.href)) {
          e.preventDefault()
        }
        originalOnClick?.(e)
      }}
      {...props}
    >
      {props.children}
    </a>
  )
}

export default styled(SafeNewTabLink)``
