import { isEqual } from 'lodash-es'
import { FOLLOWER_COUNT_RANGE_RECORD } from '@/components/kol/common/follower-level-selector'

const getFollowerLevelByFollowerCountRange = (followerCountRange: {
  followerStartFrom?: number
  followerEndTo?: number
}): number | undefined => {
  const index = Object.values(FOLLOWER_COUNT_RANGE_RECORD).findIndex((range) =>
    isEqual(range, followerCountRange),
  )

  return index >= 0 ? index + 1 : undefined
}

export default getFollowerLevelByFollowerCountRange
