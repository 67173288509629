import LocalStorageKeys from '@/constants/localstorage-keys'
import useLocalStorageSafely from '@/hooks/use-local-storage-safely'

const defaultValue = true

type UseDefaultSearch = () => {
  isDefaultSearch: boolean
  setIsDefaultSearch: (isDefaultSearch: boolean) => void
}

const useIsDefaultSearch: UseDefaultSearch = () => {
  const [isDefaultSearch, setIsDefaultSearch] = useLocalStorageSafely(
    LocalStorageKeys.DefaultSearch,
    defaultValue,
  )

  return {
    isDefaultSearch: isDefaultSearch ?? defaultValue,
    setIsDefaultSearch,
  }
}

export default useIsDefaultSearch
