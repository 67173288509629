import { z } from 'zod'
import { api } from '@/api/api'
import { type AllCountryCode } from '@/constants/country-code'

const supportedCountriesListSchema = z.object({
  countries: z.array(z.custom<AllCountryCode>()),
})

type SupportedCountriesList = z.infer<typeof supportedCountriesListSchema>

const availableCountriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    availableCountriesList: builder.query<SupportedCountriesList, void>({
      query: () => {
        return {
          url: '/available-countries-with-kol',
        }
      },
      extraOptions: {
        dataSchema: supportedCountriesListSchema,
      },
    }),
  }),
  overrideExisting: false,
})

export const { useAvailableCountriesListQuery } = availableCountriesApi
