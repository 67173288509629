import { z } from 'zod'
import { Compute } from '@/types/api-v1-types'
import { Potential } from '@/utils/detail/potential'

export const postSchema = z.object({
  post_id: z.string(),
  ig_post_id: z.string(),
  post_type: z.string(),
  publish_time: z.string().datetime(),
  platform_type: z.number(),
  like_count: z.number(),
  share_count: z.number(),
  view_count: z.number(),
  play_count: z.number(),
  comment_count: z.number(),
  url: z.string().url(),
  is_video: z.boolean(),
  is_live: z.boolean(),
  is_short: z.boolean(),
  title: z.string(),
  message: z.string(),
  text: z.string(),
  thumbnail: z.string().url(),
  is_advertorial: z.boolean(),
  is_collection_post: z.boolean(),
  kol_uuid: z.string().uuid(),
  kol_name: z.string(),
  customized_tags_names: z.array(z.string()),
  follower_count: z.number(),
  engagement_count: z.number(),
  kol_potential: z.nativeEnum(Potential).nullable(),
  kol_customized_tags_names: z.array(z.string()),
  crawled_id: z.string(),
})

export const postRadarSearchResponseSchema = z.object({
  data: z.array(postSchema),
  keywords: z.array(z.string()),
  after_page: z.string().nullable(),
  total: z.number(),
})
type PostRadarSearchResponse = z.infer<typeof postRadarSearchResponseSchema>

export type Post = z.infer<typeof postSchema>

export type PostRadarSearchResponseWithComputeTime = PostRadarSearchResponse &
  Compute
