import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { isString } from 'lodash-es'
import { userApi } from '@/api/user-api'
import {
  WORKSPACE_API_URL_PREFIX,
  WORKSPACE_ID_PLACEHOLDER,
} from '@/api/utils/get-workspace-api-url'
import { RootState } from '@/store/store'
import logger from '@/utils/logger'
import { BaseQuery } from '@/utils/redux-toolkit'

const workspaceIdInjector: (baseQuery: BaseQuery) => BaseQuery =
  (baseQuery: BaseQuery) => (args, api, extraOptions) => {
    const onlyUrl = isString(args)
    const url = onlyUrl ? args : args.url
    const isWorkspaceApi = url.includes(WORKSPACE_API_URL_PREFIX)
    const isWorkspaceIdPlaceholderIncluded = url.includes(
      WORKSPACE_ID_PLACEHOLDER,
    )

    if (!isWorkspaceApi || !isWorkspaceIdPlaceholderIncluded) {
      return baseQuery(args, api, extraOptions)
    }

    const { data: userStatus } = userApi.endpoints.fetchUserStatus.select()(
      api.getState() as RootState,
    )

    if (!userStatus?.currentWorkspaceId) {
      const error: FetchBaseQueryError = {
        status: 'CUSTOM_ERROR',
        error:
          'Calling workspace api without current workspace id is not allowed.',
      }

      logger.error({
        name: error.status,
        message: error.error,
      })

      return {
        error,
      }
    }

    const finalUrl = url.replace(
      WORKSPACE_ID_PLACEHOLDER,
      `/${userStatus.currentWorkspaceId}`,
    )

    return baseQuery(
      onlyUrl ? finalUrl : { ...args, url: finalUrl },
      api,
      extraOptions,
    )
  }

export default workspaceIdInjector
