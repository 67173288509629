import { isObject } from 'lodash-es'
import { z } from 'zod'
import {
  CountryCodeEnum,
  allCountries,
  UNLIMITED_COUNTRY_CODE,
} from '@/constants/country-code'
import { CountryCode } from '@/constants/country-currency'
import { ModelServiceWithEndAt } from '@/types/mock-api-types'
import { Nullable } from '@/types/nullable'

const AllCountryCodeSchema = z
  .nativeEnum(CountryCodeEnum)
  .or(z.literal(UNLIMITED_COUNTRY_CODE))

type AllCountryCode = z.infer<typeof AllCountryCodeSchema>

export function getDefaultCountryCode(
  checkCode: unknown,
  workspaceCountryCode?: Nullable<CountryCode>,
  availableServices?: ModelServiceWithEndAt[] | CountryCode[],
  hasUnlimited?: boolean,
): AllCountryCode {
  const usableCountries =
    (isModelServiceWithEndAt(availableServices)
      ? availableServices
          ?.filter((service) => service.serviceCategory === 'database')
          .map((service) => service.code?.split('_')[1] ?? '')
      : availableServices) ?? []
  const ownCountry = workspaceCountryCode ?? ''
  const defaultPaidCountry =
    usableCountries.length > 0 ? usableCountries[0] : null
  const defaultPaidOwnCountry =
    usableCountries.find(
      (usableCountry) => usableCountry === ownCountry && usableCountry !== '',
    ) ?? null

  const defaultOwnCountry = allCountries.find(
    (country) => country.value === ownCountry,
  )?.value

  // 使用 ?? 以處理 null
  const defaultCode =
    defaultPaidOwnCountry ??
    defaultPaidCountry ??
    defaultOwnCountry ??
    (hasUnlimited ? UNLIMITED_COUNTRY_CODE : 'tw')

  const result = usableCountries.some(
    (usableCountry) => usableCountry === checkCode,
  )
    ? (checkCode as string)
    : defaultCode

  return AllCountryCodeSchema.parse(result)
}

const isModelServiceWithEndAt = (
  availableServices?: ModelServiceWithEndAt[] | CountryCode[],
): availableServices is ModelServiceWithEndAt[] =>
  !!availableServices?.every(
    (service) => isObject(service) && 'endAt' in service,
  )
