/**
 * Art and Entertainment -> art-and-entertainment
 * Art and Entertainment/Podcast -> podcast
 * Art and Entertainment/Interior and Exterior Design -> interior-and-exterior-design
 * Medical and Health/Nutrition & Health Care -> nutrition-and-health-care
 * Media Entertainment/VTuber -> vtuber
 * Media Entertainment/K-pop -> k-pop
 * Sports -> sports
 * Sports/Hiking and Climbing -> hiking-and-climbing
 */
export const parseCustomizedTagRouterName = (tag: string): string => {
  const lastPart = tag.split('/').pop() ?? ''

  return lastPart.toLowerCase().replace(/ & /g, '-and-').replace(/ /g, '-')
}
