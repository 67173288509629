import { countryCodeSchema, FollowerLevel } from '@buggy/shared'
import { z } from 'zod'
import { RankType } from '@/api/workspaces-api'
import { Duration } from '@/components/common/duration-select'
import { tabSchema } from '@/components/kol/hooks/use-section-tabs'
import { unlimitedCountryCodeEnumSchema } from '@/constants/country-code'
import { Page } from '@/hooks/use-authorization/constants'
import searchRoute from '@/hooks/use-authorization/search-route'
import { DEFAULT_DURATION } from '@/hooks/use-kol-detail-duration'
import { CampaignKolListTab, CampaignTab } from '@/store/campaign'
import { kolPostListSorterSchema } from '@/types/schema/api/kol'
import {
  billboardMultiDimensionListSearchParamsSchema,
  billboardMultiDimensionParamsSchema,
  billboardStatisticSchema,
} from '@/types/schema/billboard/multi-dimension'
import { rankingPlatformSchema } from '@/types/schema/k6/analyze-api'
import { postTypeSchema } from '@/types/schema/post-type'
import {
  reportParamsSchema,
  reportSearchParamsSchema,
} from '@/types/schema/routes/report'
import { VisitKolDetailProperties } from '@/utils/ampli'
import { PlatformShortcode } from '@/utils/convert-platform'
import makeRoute, { emptySchema } from '@/utils/routes/make-route'

const kolDetailDurationSchema = z.enum([
  Duration.ThreeMonths,
  Duration.SixMonths,
])

export type KolDetailDuration = z.infer<typeof kolDetailDurationSchema>

export const DURATION_MONTH_INTEGER_MAP: Record<KolDetailDuration, number> = {
  [Duration.ThreeMonths]: 3,
  [Duration.SixMonths]: 6,
}

export const kolDetailPostListAdvancedFilterSchema = z.object({
  postListCustomizedTagsNames: z.string().array().optional(),
  postListPostTypes: postTypeSchema.array().optional(),
  postListDateRangeStart: z.string().datetime().optional(),
  postListDateRangeEnd: z.string().datetime().optional(),
})
export type KolDetailPostFilterConditions = z.infer<
  typeof kolDetailPostListFilterSchema
>

export const kolDetailPostListFilterSchema = z
  .object({
    keyword: z.string().optional(),
    postListSort: kolPostListSorterSchema.optional().default('publishTime'),
    postListShowSponsoredPost: z.union([z.literal(true), z.undefined()]), // default 關閉狀態是 undefined 不分業配文,
    postListNextPagingToken: z.string().optional(),
    postListPlatform: z.nativeEnum(PlatformShortcode).optional(),
  })
  .merge(kolDetailPostListAdvancedFilterSchema)

const kolDetailSearchParamsSchema = z
  .object({
    platform: z.nativeEnum(PlatformShortcode).optional(),
    duration: kolDetailDurationSchema.optional().default(DEFAULT_DURATION),
    lang: z.string().optional(),
    country_code: unlimitedCountryCodeEnumSchema.optional(),
    level: z.coerce.number().optional(),
  })
  .merge(kolDetailPostListFilterSchema)

export const kolDetailFragmentIdentifierSchema = z.enum([
  ...tabSchema.options,
  'postList',
])

const routes = {
  login: makeRoute(
    Page.Login,
    emptySchema,
    z.object({
      lang: z.string().optional(),
      identity: z.enum(['ad', 'kol']).optional().default('ad'),
    }),
  ),
  collectionsKolId: makeRoute(
    Page.CollectionsKolId,
    z.object({
      id: z.coerce.number(),
    }),
  ),
  collectionsKol: makeRoute(Page.CollectionsKol, emptySchema),
  kolId: makeRoute(
    Page.KolId,
    z.object({
      kolId: z.string(),
    }),
    kolDetailSearchParamsSchema.extend({
      from: z.string().optional(),
      fromKolId: z.string().optional(),
      fromKolDcIdHashed: z.string().optional(),
      fromKolName: z.string().optional(),
      kolDcIdHashed: z.string().optional(),
      isUnlocked: z.coerce.boolean().optional(),
      isFilterBudget: z.coerce.boolean().optional(),
      aiType: z.custom<VisitKolDetailProperties['aiType']>().optional(),
      aiFilterType: z
        .custom<VisitKolDetailProperties['aiFilterType']>()
        .optional(),
      isNewToPlatform: z.boolean().optional(),
      dataSource: z.enum(['DC', 'IQ Data']).optional(),
    }),
    kolDetailFragmentIdentifierSchema.optional(),
  ),
  kolCreate: makeRoute(
    Page.KolCreate,
    emptySchema,
    z.object({
      url: z.string(),
      from: z.string().optional(),
      isNewToPlatform: z.boolean(),
      dataSource: z.enum(['DC', 'IQ Data']),
    }),
  ),
  kolSelf: makeRoute(
    Page.KolSelf,
    emptySchema,
    kolDetailSearchParamsSchema,
    kolDetailFragmentIdentifierSchema.optional(),
  ),
  kolAgencyId: makeRoute(
    Page.KolAgencyId,
    z.object({
      kolId: z.string(),
    }),
    kolDetailSearchParamsSchema,
    kolDetailFragmentIdentifierSchema.optional(),
  ),
  kolSettings: makeRoute(
    Page.KolSettings,
    emptySchema,
    z.object({ tab: z.enum(['accounting']).optional() }),
  ),
  publicKolDetail: makeRoute(
    Page.PublicKolDetail,
    z.object({
      kolId: z.string(),
    }),
    kolDetailSearchParamsSchema.extend({
      from: z.string().optional(),
      keywordFrom: z.string().optional(),
      deviceId: z.string().optional(),
    }),
    kolDetailFragmentIdentifierSchema.optional(),
  ),
  campaignListCreateCampaign: makeRoute(
    Page.CampaignListCreateCampaign,
    emptySchema,
  ),
  campaignList: makeRoute(Page.CampaignList, emptySchema),
  campaignListDetail: makeRoute(
    Page.CampaignListDetail,
    emptySchema,
    z.object({
      campaignId: z.coerce.number(),
      tab: z.nativeEnum(CampaignTab).optional().default(CampaignTab.kolList),
      kolListTab: z
        .nativeEnum(CampaignKolListTab)
        .optional()
        .default(CampaignKolListTab.Preparing),
    }),
  ),
  competitiveBrandAnalyses: makeRoute(Page.CompetitiveBrandAnalyses),
  competitiveBrandComprehensiveAnalyses: makeRoute(
    Page.CompetitiveBrandComprehensiveAnalyses,
    z.object({
      id: z.coerce.number(),
    }),
  ),
  competitiveBrandPlatformsAnalyses: makeRoute(
    Page.CompetitiveBrandPlatformsAnalyses,
    z.object({
      id: z.coerce.number(),
    }),
  ),
  competitiveMentionContentAnalyses: makeRoute(
    Page.CompetitiveMentionContentAnalyses,
    z.object({
      id: z.coerce.number(),
    }),
    z.object({
      platform: z
        .nativeEnum(PlatformShortcode)
        .optional()
        .default(PlatformShortcode.Instagram),
      duration: z.nativeEnum(Duration).optional().default(Duration.SixMonths),
      withoutOfficialAccounts: z.coerce.boolean().optional().default(true),
      selectedKolIds: z.string().array().optional(),
      countryCode: z.string().optional(),
    }),
  ),
  competitiveMentionStatisticsAnalyses: makeRoute(
    Page.CompetitiveMentionStatisticsAnalyses,
    z.object({
      id: z.coerce.number(),
    }),
    z.object({
      countryCode: z.string().optional(),
    }),
  ),
  competitiveKeywordContentAnalyses: makeRoute(
    Page.CompetitiveKeywordContentAnalyses,
    z.object({
      id: z.coerce.number(),
    }),
    z.object({
      platform: z
        .nativeEnum(PlatformShortcode)
        .optional()
        .default(PlatformShortcode.Instagram),
      duration: z.nativeEnum(Duration).optional().default(Duration.SixMonths),
      withoutOfficialAccounts: z.coerce.boolean().optional().default(true),
      selectedKeywordIds: z.coerce.number().array().optional(),
      countryCode: z.string().optional(),
    }),
  ),
  competitiveKeywordStatisticsAnalyses: makeRoute(
    Page.CompetitiveKeywordStatisticsAnalyses,
    z.object({
      id: z.coerce.number(),
    }),
    z.object({
      countryCode: z.string().optional(),
    }),
  ),
  collectionsPostId: makeRoute(
    Page.CollectionsPostId,
    z.object({ id: z.coerce.number() }),
  ),
  collectionsPost: makeRoute(Page.CollectionsPost, emptySchema),
  inviteWorkspace: makeRoute(
    Page.InviteWorkspace,
    emptySchema,
    z.object({
      workspaceId: z.coerce.number().optional(),
      workspaceName: z.string().optional(),
    }),
  ),
  integrations: makeRoute(Page.Integrations, emptySchema),
  search: searchRoute,
  aiSearch: makeRoute(Page.AISearch, emptySchema, z.object({})),
  workspacePlan: makeRoute(
    Page.WorkspacePlan,
    emptySchema,
    z.object({ openTrialModal: z.coerce.number().optional() }),
  ),
  influencersRanking: makeRoute(
    Page.InfluencersRanking,
    emptySchema,
    z.object({
      platform: rankingPlatformSchema.optional(),
      countryCode: countryCodeSchema.optional(),
      category: z
        .union([z.literal('all'), z.string()])
        .optional()
        .default('all'),
      kolBusinessType: z.coerce.boolean().optional().default(true),
      rankType: z
        .nativeEnum(RankType)
        .optional()
        .default(RankType.WordOfMouthVolume),
    }),
  ),
  register: makeRoute(
    Page.Register,
    emptySchema,
    z.object({
      lang: z.string().optional(),
      from: z.string(),
      utm_source: z.string().optional(),
      utm_medium: z.string().optional(),
      utm_campaign: z.string().optional(),
      utm_content: z.string().optional(),
      utm_term: z.string().optional(),
    }),
  ),
  billboardCustomizedTagList: makeRoute(
    Page.BillboardCustomizedTagList,
    billboardMultiDimensionParamsSchema,
    billboardMultiDimensionListSearchParamsSchema.merge(
      z.object({
        page: z.coerce
          .number()
          .optional()
          .default(1)
          .transform((value) => value || 1),
      }),
    ),
  ),
  billboardCustomizedTagDetail: makeRoute(
    Page.BillboardCustomizedTagDetail,
    billboardMultiDimensionParamsSchema.merge(z.object({ tag: z.string() })),
    billboardMultiDimensionListSearchParamsSchema,
  ),
  billboardFollowerLevelList: makeRoute(
    Page.BillboardFollowerLevelList,
    billboardMultiDimensionParamsSchema,
    billboardMultiDimensionListSearchParamsSchema,
  ),
  billboardFollowerLevelDetail: makeRoute(
    Page.BillboardFollowerLevelDetail,
    billboardMultiDimensionParamsSchema.merge(
      z.object({ level: z.custom<FollowerLevel['billboardRouterName']>() }),
    ),
    billboardMultiDimensionListSearchParamsSchema,
  ),
  billboardStatisticList: makeRoute(
    Page.BillboardStatisticList,
    billboardMultiDimensionParamsSchema,
    billboardMultiDimensionListSearchParamsSchema,
  ),
  billboardStatisticDetail: makeRoute(
    Page.BillboardStatisticDetail,
    billboardMultiDimensionParamsSchema.merge(
      z.object({
        statistic: billboardStatisticSchema,
      }),
    ),
    billboardMultiDimensionListSearchParamsSchema,
  ),
  kolAssetCatalog: makeRoute(Page.KolAssetCatalog, emptySchema),
  report: makeRoute(Page.Report),
  reportId: makeRoute(
    Page.ReportId,
    reportParamsSchema,
    reportSearchParamsSchema,
  ),
}

export default routes
