import { Epic, StateObservable } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { AppContext } from '@/api/executor/app-context'
import { userApi } from '@/api/user-api'
import { CrawlerStatus } from '@/constants/crawler-status'
import {
  fetchCampaignCrawlerStatus,
  fetchCampaignCrawlerStatusFailure,
  fetchCampaignCrawlerStatusSuccess,
  fetchContent,
  fetchContentFailure,
  fetchContentSuccess,
  fetchReportCrawlingStatus,
  fetchReportCrawlingStatusFailure,
  fetchReportCrawlingStatusSuccess,
  getReport,
  setAllChartData,
  setReport,
  triggerCrawler,
  triggerCrawlerFailure,
  triggerCrawlerSuccess,
} from '@/store/campaign-report'
import { RootState } from '@/store/store'

const fetchCampaignCrawlerStatusEpic: Epic = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(fetchCampaignCrawlerStatus.match),
    switchMap((action) => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      if (workspaceId) {
        return from(
          AppContext.ApiExecutor.getCampaignCrawlerStatus(
            action.payload,
            workspaceId,
          ),
        ).pipe(
          map(({ data }) => {
            return fetchCampaignCrawlerStatusSuccess(data)
          }),
          catchError((error) => {
            return of(fetchCampaignCrawlerStatusFailure(JSON.stringify(error)))
          }),
        )
      } else {
        return of(
          fetchCampaignCrawlerStatusFailure('Current workspace is undefined'),
        )
      }
    }),
  )

const fetchReportCrawlingStatusEpic: Epic = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(fetchReportCrawlingStatus.match),
    switchMap((action) => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      if (workspaceId) {
        return from(
          AppContext.ApiExecutor.getCampaignCrawlerStatus(
            action.payload,
            workspaceId,
            [CrawlerStatus.Running],
          ),
        ).pipe(
          map(({ data }) => {
            return fetchReportCrawlingStatusSuccess(data.length > 0)
          }),
          catchError((error) => {
            return of(fetchReportCrawlingStatusFailure(JSON.stringify(error)))
          }),
        )
      } else {
        return of(
          fetchReportCrawlingStatusFailure('Current workspace is undefined'),
        )
      }
    }),
  )

const fetchContentEpic: Epic = (action$, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(fetchContent.match),
    switchMap((action) => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      if (workspaceId) {
        return from(
          AppContext.ApiExecutor.getCampaignContent({
            ...action.payload,
            workspaceID: workspaceId,
          }),
        ).pipe(
          map(({ data }) => {
            return fetchContentSuccess(data)
          }),
          catchError((error) => {
            return of(fetchContentFailure(error))
          }),
        )
      } else {
        return of(
          fetchContentFailure(new Error('Current workspace is undefined')),
        )
      }
    }),
  )

const getReportEpic: Epic = (action$) =>
  action$.pipe(
    filter(getReport.match),
    switchMap((action) => {
      const { campaignID, workspaceId } = action.payload

      return from(
        AppContext.ApiExecutor.getCampaignReport(campaignID, workspaceId),
      ).pipe(
        map(({ data }) => {
          const budget = data?.statistics.reduce((sum, statistic) => {
            return sum + (statistic.budget ?? 0)
          }, 0)

          return setReport({
            ...data,
            budget,
            statistics: data.statistics.map((d) => {
              const kol = data.kols?.find((kol) => kol.uuid === d.kolUUID)

              return {
                ...d,
                UUID: d.kolUUID,
                platform: d.platformType,
                kolName: kol?.name || '',
              }
            }),
          })
        }),
        catchError(() => {
          return of(setReport())
        }),
      )
    }),
  )

const triggerCrawlerEpic: Epic = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(triggerCrawler.match),
    switchMap((action) => {
      const { campaignID } = action.payload
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      if (workspaceId) {
        return from(
          AppContext.ApiExecutor.triggerCrawler(campaignID, workspaceId),
        ).pipe(
          map(() => {
            return triggerCrawlerSuccess({ campaignID })
          }),
          catchError(() => {
            return of(triggerCrawlerFailure())
          }),
        )
      } else {
        return of(triggerCrawlerFailure())
      }
    }),
  )

const setAllChartDataEpic: Epic = (action$) =>
  action$.pipe(
    filter(setReport.match),
    switchMap((action) => {
      const data = (action.payload?.statistics as any) ?? []
      return of(setAllChartData({ data }))
    }),
  )

const campaignReportEpics = [
  fetchCampaignCrawlerStatusEpic,
  fetchReportCrawlingStatusEpic,
  fetchContentEpic,
  getReportEpic,
  triggerCrawlerEpic,
  setAllChartDataEpic,
]

export default campaignReportEpics
