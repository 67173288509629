// TODO: remove type any
import { CrawlerStatus as CrawlerJobStatus } from '@/constants/crawler-status'
import { PostType } from '@/constants/post-type'
import { Nullable } from '@/types/nullable'
import { ChartRowData } from '@/types/schema/chart-data-schema'

export interface ReportKOL {
  id: number
  UUID: string
  /**
   * @todo This actually is a object, but we treat it as string.
   * @see components/auto-report/content/TagReportList.tsx:74
   */
  platforms?: any[]
  crawlerStatuses?: any[]
  customizedTagsNames: string[]
}

export interface Report {
  id: number | 'demo'
  name: string
  ownerID?: number
  owner?: any
  reportPosts?: any[]
  terms?: any[]
  pinnedComments?: any[]
  valueEstimationWeight: ValueEstimationWeight
  userDefinedIndustryData?: IndustrySetting
  budget?: number
  category?: string
  kolAmount?: number
  startAt: string
  endAt: string
  updatedAt?: string
  kolsInfo: {
    name: string
    id: string
  }[]
  platforms: {
    name: string
    id: string
  }[]
  commentCrawlerStatus: CrawlerStatus
  postCrawlerStatus: CrawlerStatus
  crawlerJobs: any[]
  succeededCrawlerJobs: any[]
  lastUpdatedAt: string
  reportLastUpdatedAt: string
  reportKOLs?: ReportKOL[]
  keywords: string[]
  reportStatistics?: ChartRowData[]
  refreshUntil?: string
  guestVisibility: {
    [key: string]: boolean
  }
  latestFinishedCrawlerJob: any[]
  latestCrawlerJob?: {
    uuid?: string
    status?: CrawlerJobStatus
    updatedAt?: string
    isRead?: boolean
  }
  workspaceID: number
}

interface CrawlerStatus {
  failedAt: string
  finishedAmount: number
  lastUpdatedAt: string
  scanning: boolean
  totalAmount: number
}

export enum PlatformType {
  All = -1,
  YouTube = 0,
  Facebook = 1,
  Instagram = 3,
  TikTok = 4,
  Twitter = 5,
  Other = 999, // 目前只有前端用到，後端不會有這個值
}

export interface ValueEstimationWeight {
  [key: string]: ValuationSetting
}

export interface ValuationSetting {
  viewValue: number
  commentValue: number
  likeValue: number
  shareValue: number
  onlineValue: number
}

export interface IndustrySetting {
  reportID: number | 'demo'
  userDefined: true
  industryType: string
  avgEngagementNum: number
  avgViewNum: number
  avgCPE: number
  avgCPV: number
  avgCPEV: number
}

export interface ReportContent {
  id: number
  budget?: number
  blogLink?: string
  clickCount?: number
  endAt?: string
  impressionCount?: number
  kolID: string
  kolName: string
  otherMetric?: any
  pcu?: number
  postCrawlID?: number
  postType?: string
  reachCount?: number
  reportID?: number | 'demo'
  startAt?: string
  unavailableAt?: string
  url?: string
  viewCount?: number
  reportKolID?: number
  handfilledID?: number
  youTubeImpressionCount?: number
  storyReachCount?: number
  storyClickCount?: number
  likeCount?: number
  commentCount?: number
  shareCount?: number
  articleViewCount?: number
  useHandfilled?: boolean
  matchedKeywords?: string[]
  reportPostID: number
  isHidden: Nullable<boolean>
  UUID: string
  uniqueKey: string
}

export interface ModelReportStatistics {
  reportKolID: number
  articleViewCount: Nullable<number>
  budget: Nullable<number>
  categories?: string[]
  clickCount?: number
  commentCount?: number
  commentValue?: number
  createdAt?: string
  deletedAt?: string
  engagementCount?: number
  followerCount?: number
  id?: number
  lastScannedAt?: string
  likeCount: number
  likeValue?: number
  matchedKeywords?: string[]
  mediaValue?: number
  name?: string
  pcu?: number
  pcuValue?: number
  platform?: number
  postCrawlID?: number
  postType: PostType
  publishTime?: string
  reachCount?: number
  report?: Report
  reportID?: number
  reportPostID?: number
  shareCount?: number
  shareValue?: number
  storyClickCount?: number
  storyReachCount: Nullable<number>
  text?: string
  time?: string
  unavailableAt?: string
  updatedAt?: string
  url?: string
  UUID?: string
  viewCount?: number
  viewValue?: number
  youTubeImpressionCount?: number
  thumbnailURL: Nullable<string>
  platformType: PlatformType
  isShort: boolean
  uniqueKey: string
  crawling: Nullable<boolean>
  isHidden: Nullable<boolean>
  useHandfilled: boolean
  blogLink: Nullable<string>
  playCount: Nullable<number>
  handfilledID: Nullable<number>
  impressionCount: Nullable<number>
  postID: string
  crawledID: string
  igPostId?: string
}
