import { z } from 'zod'

const reportModeSchema = z.enum(['preview', 'edit']).optional()

export const reportParamsSchema = z.object({
  workspaceId: z.coerce.number(),
  reportId: z.union([z.literal('demo'), z.coerce.number()]),
})

export const reportSearchParamsSchema = z.object({ mode: reportModeSchema })
