import { Icon } from '@buggy/shared'
import { useRouter } from 'next/router'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SafeNewTabLink } from '@/components/safe-new-tab-link'
import { SearchMode } from '@/constants/search/search-mode'
import routes from '@/hooks/use-authorization/routes'
import { useIntl } from '@/i18n/hooks/use-intl'
import { KolSchemaModel } from '@/types/schema/kol-schema'
import { ampli, SearchSimilarKolProperties } from '@/utils/ampli'

interface SearchSimilarKolsLinkProps
  extends Pick<KolSchemaModel, 'similarKolPlatform'> {
  at: SearchSimilarKolProperties['at']
  kolId: string
  kolDcIdHashed?: string
  style: 'link' | 'text'
}

const SearchSimilarKolsLink: FunctionComponent<SearchSimilarKolsLinkProps> = ({
  at,
  kolId,
  kolDcIdHashed,
  similarKolPlatform,
  style,
}) => {
  const { formatMessage } = useIntl()
  const router = useRouter()

  if (!similarKolPlatform) {
    return null
  }

  return (
    <StyledLink
      href={routes.search.stringifyUrl(
        {},
        {
          mode: SearchMode.SimilarKol,
          similarKolId: kolId,
          similarKolDcIdHashed: kolDcIdHashed,
          similarKolPlatform,
          from: 'Search Similar Influencer',
        },
      )}
      onClick={(event): void => {
        event.stopPropagation()

        ampli.searchSimilarKol({
          kolId,
          kolDcId: kolDcIdHashed,
          at,
          path: router.asPath,
        })
      }}
    >
      {style === 'link' && <Icon fontSize={16} name='search' />}
      <span>
        {formatMessage({ id: 'search:result_keyword_search_similar_kols_act' })}
      </span>
      {style === 'text' && <Icon fontSize={16} name='chevron-right' />}
    </StyledLink>
  )
}

const StyledLink = styled(SafeNewTabLink)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

export default SearchSimilarKolsLink
