import { CurrencyCode } from '@buggy/shared'
import { I18nId } from '@/i18n/config'

/**
 * @deprecated 後續改用 CountryCodeEnum from constants/country-code
 */
export enum CountryCode {
  TW = 'tw',
  HK = 'hk',
  JP = 'jp',
  CN = 'cn',
  MY = 'my',
  SG = 'sg',
  TH = 'th',
  VN = 'vn',
  KR = 'kr',
  ID = 'id',
  PH = 'ph',
  IN = 'in',
  US = 'us',
  CA = 'ca',
  AU = 'au',
}

/**
 * @deprecated 後續改用 getCountryCurrency 取得對應的 currency i18n
 */

export const CurrencyI18nId: Record<CurrencyCode, I18nId> = {
  [CurrencyCode.TWD]: 'workspace:currency_TWD',
  [CurrencyCode.HKD]: 'workspace:currency_HKD',
  [CurrencyCode.JPY]: 'workspace:currency_JPY',
  [CurrencyCode.CNY]: 'workspace:currency_CNY',
  [CurrencyCode.MYR]: 'workspace:currency_MYR',
  [CurrencyCode.SGD]: 'workspace:currency_SGD',
  [CurrencyCode.THB]: 'workspace:currency_THB',
  [CurrencyCode.VND]: 'workspace:currency_VND',
  [CurrencyCode.KRW]: 'workspace:currency_KRW',
  [CurrencyCode.IDR]: 'workspace:currency_IDR',
  [CurrencyCode.PHP]: 'workspace:currency_PHP',
  [CurrencyCode.INR]: 'workspace:currency_INR',
  [CurrencyCode.USD]: 'workspace:currency_USD',
  [CurrencyCode.CAD]: 'workspace:currency_CAD',
  [CurrencyCode.AUD]: 'workspace:currency_AUD',
}

/**
 * @deprecated 後續改用 allCountries
 */
export const COUNTRY_CURRENCY_LIST = [
  { countryCode: CountryCode.HK, currencyCode: CurrencyCode.HKD },
  { countryCode: CountryCode.TW, currencyCode: CurrencyCode.TWD },
  { countryCode: CountryCode.JP, currencyCode: CurrencyCode.JPY },
  { countryCode: CountryCode.CN, currencyCode: CurrencyCode.CNY },
  { countryCode: CountryCode.MY, currencyCode: CurrencyCode.MYR },
  { countryCode: CountryCode.SG, currencyCode: CurrencyCode.SGD },
  { countryCode: CountryCode.TH, currencyCode: CurrencyCode.THB },
  { countryCode: CountryCode.VN, currencyCode: CurrencyCode.VND },
  { countryCode: CountryCode.KR, currencyCode: CurrencyCode.KRW },
  { countryCode: CountryCode.ID, currencyCode: CurrencyCode.IDR },
  { countryCode: CountryCode.PH, currencyCode: CurrencyCode.PHP },
  { countryCode: CountryCode.IN, currencyCode: CurrencyCode.INR },
  { countryCode: CountryCode.US, currencyCode: CurrencyCode.USD },
  { countryCode: CountryCode.CA, currencyCode: CurrencyCode.CAD },
  { countryCode: CountryCode.AU, currencyCode: CurrencyCode.AUD },
] as const
