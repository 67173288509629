import PubNub, {
  FileEvent,
  ListenerParameters,
  MessageEvent,
  SubscribeParameters,
} from 'pubnub'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useDeepCompareEffect } from 'react-use'
import { useFetchUserStatusQuery, usePubnubLoginQuery } from '@/api/user-api'
import { useAuth } from '@/hooks/use-auth'
import {
  receiveFileEvent,
  receiveMessageEvent,
  setConversationList,
  setLastReadTimeKey,
} from '@/store/chatroom'

let pubNubClient: PubNub | undefined

export const getPubNubClient = (): PubNub | undefined => {
  return pubNubClient
}

const usePubNubClient = (
  props: SubscribeParameters = {},
): { pubNub: PubNub | undefined; isFetching: boolean } => {
  const dispatch = useDispatch()

  const { userInfo, isFetching: isFetchingUserInfo } = useAuth()
  const { data: userStatus, isFetching: isFetchingUserStatus } =
    useFetchUserStatusQuery()
  const {
    data: pubNubLoginResponse,
    isFetching: isFetchingPubNubLoginResponse,
  } = usePubnubLoginQuery()

  const userId = userInfo?.id
  const userType = userInfo?.type
  const pubNubAuthKey = pubNubLoginResponse?.data.token
  const uuid = useMemo((): string | undefined => {
    if (!userId || !userType) {
      return undefined
    }

    if (userType !== 'ad') {
      return `campaign_${userType}_user${userId}`
    }

    if (!userStatus?.currentWorkspaceId) {
      return undefined
    }

    return `campaign_workspace${userStatus.currentWorkspaceId}`
  }, [userStatus?.currentWorkspaceId, userId, userType])

  const pubNub = useMemo(() => {
    const publishKey = process.env.NEXT_PUBLIC_PUBNUB_PUBLISH_KEY
    const subscribeKey = process.env.NEXT_PUBLIC_PUBNUB_SUBSCRIBE_KEY

    if (!subscribeKey || !publishKey) {
      console.error('PubNub subscribe / publish key should not be undefined.')
      return undefined
    }

    if (uuid && pubNubAuthKey) {
      return new PubNub({
        authKey: pubNubAuthKey,
        publishKey,
        subscribeKey,
        userId: uuid,
      })
    } else {
      return undefined
    }
  }, [pubNubAuthKey, uuid])

  useDeepCompareEffect(() => {
    if (pubNub) {
      const channelsToSubscribe = props.channels ?? []

      const subscribedChannels = pubNub.getSubscribedChannels() || []

      const subscribeChannels = channelsToSubscribe.filter(
        (channel) => !subscribedChannels.includes(channel),
      )
      const unsubscribeChannels = subscribedChannels.filter(
        (channel) => !channelsToSubscribe.includes(channel),
      )

      if (subscribeChannels.length) {
        pubNub.subscribe({
          channels: subscribeChannels,
          withPresence: props.withPresence,
        })
      }

      if (unsubscribeChannels.length) {
        pubNub.unsubscribe({
          channels: unsubscribeChannels,
        })
      }
    }
  }, [pubNub, props])

  useEffect(() => {
    if (pubNub) {
      const listener: ListenerParameters = {
        file(fileEvent: FileEvent) {
          dispatch(receiveFileEvent(fileEvent))
        },
        message: (messageEvent: MessageEvent) => {
          dispatch(receiveMessageEvent(messageEvent))
        },
      }

      pubNub.addListener(listener)
      pubNubClient = pubNub
      dispatch(setLastReadTimeKey(`last_read_by_${pubNub.getUUID()}`))

      return () => {
        dispatch(setConversationList([]))
        pubNub.removeListener(listener)
      }
    }
  }, [dispatch, pubNub])

  return {
    pubNub,
    isFetching:
      isFetchingUserInfo ||
      isFetchingUserStatus ||
      isFetchingPubNubLoginResponse,
  }
}

export default usePubNubClient
