import { countryCodeSchema } from '@buggy/shared'
import { z } from 'zod'
import { RankType } from '@/api/workspaces-api'
import { platformShortcodeSchema } from '@/types/schema/platform/platform-shortcode-schema'

export const rankingPlatformSchema = z.enum([
  platformShortcodeSchema.enum.ig,
  platformShortcodeSchema.enum.fb,
  platformShortcodeSchema.enum.yt,
])
export type RankingPlatformType = z.infer<typeof rankingPlatformSchema>

const rankingCountryCodeListSchema = z.enum([
  countryCodeSchema.enum.tw,
  countryCodeSchema.enum.jp,
  countryCodeSchema.enum.hk,
  countryCodeSchema.enum.my,
  countryCodeSchema.enum.my,
  countryCodeSchema.enum.th,
  countryCodeSchema.enum.vn,
  countryCodeSchema.enum.sg,
])
type RankingCountryCodeListType = z.infer<typeof rankingCountryCodeListSchema>

export interface AnalyzeRankingRequest {
  platform: RankingPlatformType
  countryCode?: RankingCountryCodeListType
  category?: string
  kolBusinessType: boolean
  platform_follower_count_greater_than?: number
  platform_follower_count_less_than_or_equal?: number
  rankType: RankType
}

export interface AnalyzeRankingItem {
  kolId: number
  uuid: string
  name: string
  followerCount: number | null
  rank: number
  wordOfMouthVolume: number | null
  engagementRate: number | null
  viewRate: number | null
}

export interface AnalyzeRankingResponse {
  lastUpdatedAt: string | null
  data: AnalyzeRankingItem[]
}
