enum LocalStorageKeys {
  TrialCode = 'trial_code',
  Jwt = 'radar:jwt',
  CollectionView = 'collection-view',
  CollectionPlatform = 'collection-platform',
  CollectionPeriod = 'collection-period',
  SearchLayout = 'search-layout',
  SiderCollapsed = 'isSiderCollapsed',
  LoginTime = 'loginTime',
  SearchId = 'radar:search-id',
  DefaultSearch = 'default-search',
}

export default LocalStorageKeys
