import {
  type BaseQueryFn,
  FetchArgs,
  type FetchBaseQueryError,
  type FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/react'
import { isString } from 'lodash-es'
import isFunction from 'lodash-es/isFunction'
import { ExecutableRequest } from '@/types/api'

type ExecutableQuery = BaseQueryFn<
  string | (FetchArgs & ExecutableRequest),
  unknown,
  FetchBaseQueryError,
  unknown,
  FetchBaseQueryMeta
>

const beforeQueryStarts: (baseQuery: ExecutableQuery) => ExecutableQuery =
  (baseQuery: ExecutableQuery) => async (args, api, extraOptions) => {
    if (isString(args)) {
      return baseQuery(args, api, extraOptions)
    }

    if (isFunction(args.beforeQueryStarts)) {
      args.beforeQueryStarts()
    } else if (isFunction(args.beforeQueryStarts?.then)) {
      await args.beforeQueryStarts
    }

    return baseQuery(args, api, extraOptions)
  }

export default beforeQueryStarts
