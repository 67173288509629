import { Checkbox, Radio } from 'antd'
import { isEmpty, isNil, xorWith } from 'lodash-es'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import {
  ALL_BUSINESS_OPTION_VALUES,
  BUSINESS_OPTIONS,
} from '@/constants/business-type'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Condition } from '@/types/api/search'

export const EXCLUDED_ALL_KOL_TYPE_CODE = 'all'
export const ANY_KOL_TYPE_CODE = 'any'
export const WITH_OFFICIAL_FILTER_KEY = 'filter_kol_type'

const getSelectedIncludedBusiness = (filterKolType?: string): string[] => {
  if (isNil(filterKolType)) {
    return ALL_BUSINESS_OPTION_VALUES
  }
  const excludedKolTypeList =
    filterKolType === EXCLUDED_ALL_KOL_TYPE_CODE
      ? ALL_BUSINESS_OPTION_VALUES
      : filterKolType.split(',')
  const includedKolTypeList = xorWithAllBusinessOptions(excludedKolTypeList)
  return includedKolTypeList
}

const xorWithAllBusinessOptions = (businessOptions: string[]): string[] => {
  return xorWith(ALL_BUSINESS_OPTION_VALUES, businessOptions)
}

interface WithOfficialFilterContentProps {
  filterValues: Condition
  onValuesChange: (changedValues: Condition) => void
}

const WithOfficialFilterContent: FunctionComponent<
  WithOfficialFilterContentProps
> = ({ filterValues, onValuesChange }) => {
  const { formatMessage } = useIntl()

  const includedBusiness = useMemo(
    () => getSelectedIncludedBusiness(filterValues[WITH_OFFICIAL_FILTER_KEY]),
    [filterValues],
  )

  const [isOpen, setIsOpen] = useState(
    filterValues.filter_kol_type !== EXCLUDED_ALL_KOL_TYPE_CODE,
  )

  const businessOptions = BUSINESS_OPTIONS.map(({ value, label }) => ({
    label: formatMessage({ id: label }),
    value,
  }))

  const applyChanges = useCallback(
    (includedBusiness: string[]): void => {
      const getKolTypeParams = (): string | undefined => {
        if (BUSINESS_OPTIONS.length === includedBusiness.length) {
          return ANY_KOL_TYPE_CODE
        }

        return isEmpty(includedBusiness)
          ? EXCLUDED_ALL_KOL_TYPE_CODE
          : xorWithAllBusinessOptions(includedBusiness).join(',')
      }

      onValuesChange({
        filter_kol_type: getKolTypeParams(),
      })
    },
    [onValuesChange],
  )

  useUpdateEffect(() => {
    const checkAllBusiness = (checked: boolean): void => {
      applyChanges(checked ? BUSINESS_OPTIONS.map(({ value }) => value) : [])
    }

    checkAllBusiness(isOpen)
  }, [isOpen])

  useUpdateEffect(() => {
    if (filterValues[WITH_OFFICIAL_FILTER_KEY] !== EXCLUDED_ALL_KOL_TYPE_CODE) {
      return
    }
    setIsOpen(false)
  }, [filterValues])

  return (
    <SelectWrapper>
      <div>
        <RadioGroup
          value={isOpen}
          onChange={(event): void => {
            setIsOpen(event.target.value)
          }}
        >
          <Radio value={false}>
            {formatMessage({ id: 'search:filter_exclude' })}
          </Radio>
          <Radio value={true}>
            {formatMessage({ id: 'search:filter_include' })}
          </Radio>
        </RadioGroup>
        {isOpen && (
          <>
            <SubTitle>
              {formatMessage({ id: 'general:field_name_property' })}
              {formatMessage({ id: 'general:colon' })}
            </SubTitle>
            <StyledBusinessCheckboxGroup
              options={businessOptions}
              value={includedBusiness}
              onChange={(v: string[]) => {
                applyChanges(v)
              }}
            />
          </>
        )}
      </div>
    </SelectWrapper>
  )
}

const StyledBusinessCheckboxGroup = styled(Checkbox.Group)`
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  gap: 20px;
`

const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const SubTitle = styled.div`
  color: ${({ theme }): string => theme.colors.text.secondary};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
`

const SelectWrapper = styled.div`
  padding: 0 4px;
  overflow-y: auto;

  ${SubTitle} {
    margin: 20px 0 12px;
  }
`

export default WithOfficialFilterContent
