import { DeleteOutlined } from '@ant-design/icons'
import { Button, Drawer, Tabs } from 'antd'
import { isEqual, pick } from 'lodash-es'
import React, { FunctionComponent, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { Icon } from '@/components/custom-icon'
import AudienceFilterGroup from '@/components/search/filters/audience-filter-group'
import { AdvancedFilterButton } from '@/components/search/filters/common/advanced-filter-button'
import EngagementPotentialFilterGroup from '@/components/search/filters/engagement-potential-filter-group'
import KolSelfFilterGroup from '@/components/search/filters/kol-self-filter-group'
import OthersFilterGroup from '@/components/search/filters/others-filter-group'
import useCountries from '@/components/search/hooks/use-countries'
import { defaultSearchConditions } from '@/constants/search-conditions'
import useSearch from '@/hooks/use-search'
import useSearchId from '@/hooks/use-search-id'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Condition } from '@/types/api/search'

const ADVANCED_FILTER_KEYS: (keyof Condition)[] = [
  //KolSelfFilterGroup //
  'gender',
  'minQuotationPrice',
  'maxQuotationPrice',
  'follower_start_from',
  'follower_end_to',
  'followerRangeLevel',
  'customized_tags_names',
  'country_code',
  'languages',
  //EngagementPotentialFilterGroup //
  'potential',
  'fansUpRateBegin',
  'fansUpRateEnd',
  'interactiveRateBegin',
  'interactiveRateEnd',
  'avgPvRateBegin',
  'avgPvRateEnd',
  //AudienceFilterGroup //
  'audienceGenderCode',
  'audienceGenderRateBegin',
  'audienceGenderRateEnd',
  'audienceAgeCode',
  'audienceAgeRateBegin',
  'audienceAgeRateEnd',
  'audienceGeoCode',
  'audienceGeoRateBegin',
  'audienceGeoRateEnd',
  //OthersFilterGroup //
  'verifiedKol',
  'post_start_time',
  'post_end_time',
  'filter_collection',
  'filter_cooperation',
  'filter_kol_type',
  'filter_unrecommended',
]

const AdvancedFilters: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { kolQueryParams, handleSearch } = useSearch()
  const advancedCondition = pick(kolQueryParams, ADVANCED_FILTER_KEYS)
  const { value: searchId } = useSearchId()
  const { defaultCountry } = useCountries({ hasUnlimited: true })
  const [activeTab, setActiveTab] = useState('tab-1')
  const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false)

  const [filters, setFilters] = useState<Condition>(advancedCondition)

  const defaultFilterValues = Object.assign(
    {},
    pick(defaultSearchConditions, ADVANCED_FILTER_KEYS),
    {
      country_code: defaultCountry,
    },
  )

  useUpdateEffect(() => {
    const beacon = document.getElementById('beacon-container')?.style
    const body = document.body.style

    if (openAdvancedFilter) {
      beacon?.setProperty('display', 'none')
      body.overflowY = 'hidden'
    } else {
      beacon?.setProperty('display', 'block')
      body.overflowY = 'auto'
    }
  }, [openAdvancedFilter])

  useUpdateEffect(() => {
    setFilters(advancedCondition)
  }, [searchId])

  return (
    <Wrapper ref={wrapperRef}>
      <AdvancedFilterButton
        data-tour-search-kol-step='2'
        icon={<Icon type='filterIcon' />}
        selected={!isEqual(defaultFilterValues, filters)}
        onClick={(): void => setOpenAdvancedFilter(true)}
      >
        {formatMessage({ id: 'general:filter_options_more' })}
      </AdvancedFilterButton>
      <Drawer
        closable={false}
        getContainer={wrapperRef?.current ?? document.body}
        open={openAdvancedFilter}
        width={600}
        onClose={(): void => setOpenAdvancedFilter(false)}
      >
        <Tabs
          activeKey={activeTab}
          items={[
            {
              key: 'tab-0',
              label: formatMessage({ id: 'general:filter_conditions' }),
              disabled: true,
            },
            {
              key: 'tab-1',
              label: formatMessage({ id: 'general:about_influencer' }),
              children: (
                <KolSelfFilterGroup
                  filterValues={filters}
                  onValuesChange={(changedValues: Condition): void => {
                    setFilters({ ...filters, ...changedValues })
                  }}
                />
              ),
            },
            {
              key: 'tab-2',
              label: formatMessage({ id: 'general:interactive_potential' }),
              children: (
                <EngagementPotentialFilterGroup
                  filterValues={filters}
                  onValuesChange={(changedValues: Condition): void => {
                    setFilters({ ...filters, ...changedValues })
                  }}
                />
              ),
            },
            {
              key: 'tab-3',
              label: formatMessage({ id: 'search:audience' }),
              children: (
                <AudienceFilterGroup
                  filterValues={filters}
                  onValuesChange={(changedValues: Condition): void => {
                    setFilters({ ...filters, ...changedValues })
                  }}
                />
              ),
            },
            {
              key: 'tab-4',
              label: formatMessage({ id: 'general:kol_radar_exclusive' }),
              children: (
                <OthersFilterGroup
                  filterValues={filters}
                  onValuesChange={(changedValues: Condition): void => {
                    setFilters({ ...filters, ...changedValues })
                  }}
                />
              ),
            },
          ]}
          tabPosition='left'
          onChange={(key): void => setActiveTab(key)}
        />
        <Footer>
          <Button
            icon={<DeleteOutlined />}
            onClick={(): void => {
              setFilters(defaultFilterValues)
            }}
          >
            {formatMessage({ id: 'general:action_clear_all_filters' })}
          </Button>
          <Button
            type='primary'
            onClick={(): void => {
              handleSearch({
                ...kolQueryParams,
                ...filters,
              })
              setOpenAdvancedFilter(false)
            }}
          >
            {formatMessage({ id: 'general:action_show_results' })}
          </Button>
        </Footer>
      </Drawer>
    </Wrapper>
  )
}

const FIRST_Z_INDEX = 2
const SECOND_Z_INDEX = 1

const Footer = styled.div`
  position: absolute;
  width: 100%;
  z-index: ${FIRST_Z_INDEX};
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 24px 25px;
  border-top: 1px solid ${({ theme }): string => theme.colors.border.divider};
  background: ${({ theme }): string => theme.colors.background.white};

  button {
    height: 40px;
  }

  > :first-child {
    margin-right: 16px;
    border: none;
    box-shadow: none;
  }
`

export const ButtonRadioGroupWrapper = styled.div`
  .ant-radio-group {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    > label {
      flex: 1;
      text-align: center;
      white-space: nowrap;
    }
  }
`

export const Label = styled.div`
  color: ${({ theme }): string => theme.colors.text.secondary};
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
`

const Wrapper = styled.div`
  .ant-drawer-body {
    padding: 0;

    .ant-tabs {
      position: relative;

      .ant-tabs-nav {
        z-index: ${SECOND_Z_INDEX};
        min-height: 100%;
        top: 0;
        position: fixed;
        min-width: 160px;

        .ant-tabs-nav-wrap {
          padding-top: 24px;
          box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
        }
      }

      .ant-tabs-tab-active {
        background: ${({ theme }): string => theme.colors.background.red};
      }

      .ant-tabs-tab {
        max-width: 160px;
        font-size: 14px;
        line-height: 140%;
        padding: 12px 24px;
        white-space: normal;
        text-align: left;

        :not(:first-child) {
          margin: 0 !important;
        }

        :first-child {
          cursor: default;
          color: ${({ theme }): string => theme.colors.text.secondary};
          line-height: 140%;
          font-size: 12px;
        }
      }

      .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active {
        color: inherit;
      }

      .ant-tabs-tabpane {
        padding: 0 0 88px 0 !important;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
`

export const FilterGroupWrapper = styled.div`
  padding: 24px 24px 24px 184px;

  h4 {
    color: ${({ theme }): string => theme.colors.text.primary};
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 28px;
  }

  ${Label} {
    margin: 12px 0 24px;
  }

  > :not(:last-child) {
    margin-bottom: 36px;
  }
`

export default AdvancedFilters
