import isUndefined from 'lodash-es/isUndefined'
import { z } from 'zod'
import { CUSTOMIZED_TAG_I18N_MAP } from '@/constants/customized-tag'
import { platformTypeSchema } from '@/types/schema/platform/platform-type-schema'

export interface CustomizedTag {
  customized_tag_name: string
  sub_customized_tag_names: CustomizedTag[]
}

export const customizedTagSchema = z.object({
  customized_tag_name: z.string().refine((val) => {
    return !isUndefined(CUSTOMIZED_TAG_I18N_MAP[val])
  }),
  sub_customized_tag_names: z.array(z.lazy(() => customizedTagSchema)),
})

export const quickSearchKolSchema = z.object({
  uuid: z.string(),
  dc_id_hashed: z.string().nullable(),
  kol_name: z.string(),
  follower_count: z.number(),
  is_verified: z.boolean(),
  is_radar_star: z.boolean(),
  platform_type_list: platformTypeSchema.array(),
})
export type QuickSearchKol = z.infer<typeof quickSearchKolSchema>
