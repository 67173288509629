import { useRouter } from 'next/router'
import { useMount } from 'react-use'
import { Page } from '@/hooks/use-authorization/constants'
import useSearchId from '@/hooks/use-search-id'
import env from '@/utils/env'

// 用於初始化 kol-radar 時，檢查當前的 searchId 是否有效
const useSearchIdValidator = (): void => {
  const searchId = useSearchId()
  const router = useRouter()

  useMount(() => {
    const { referrer } = document

    const fromAdKolPage = referrer.includes(Page.KolId.replace('[kolId]', ''))
    const fromSimilarKolOrRelatedKol =
      router.asPath.includes('similarInfluencer') ||
      router.asPath.includes('relatedInfluencer')

    if (fromSimilarKolOrRelatedKol && fromAdKolPage) {
      return
    }

    // 來自其他網站 or social share 的流量，其 search id 一率視為過期
    const fromSameSite = referrer.includes(
      env.DEVELOPMENT_MODE ? 'localhost' : env.APP_URL,
    )

    // 僅 search 和 ai-search 會產新鮮的 search id，來自其他頁的 search id 一率視為過期
    const fromSearchPage =
      referrer.includes(Page.Search) || referrer.includes(Page.AISearch)

    // 僅 ad kol 頁的事件會需要 search-id，若身處其他頁，則將現在的 search id 視為過期
    const inAdKolPage = [Page.KolId].includes(router.route as Page)

    if (!fromSameSite || !fromSearchPage || !inAdKolPage) {
      searchId.remove()
    }
  })
}

export default useSearchIdValidator
