import { Popover } from 'antd'
import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFetchCustomizedTagListQuery } from '@/api/customized-tag-api'
import CustomizedContentMultiMode from '@/components/search/filters/customized-content-multi-mode'
import CustomizedTagContentSingleMode from '@/components/search/filters/customized-content-single-mode'

interface CustomizedTagSelectorProps {
  children: ReactNode
  customizedTagsNames?: string
  onVisibleChange?: (visible: boolean) => void
  onValuesChange: (customizedTagsNames?: string) => void
  onSearchChange?: (value: string) => void
  isSearchable?: boolean
  mode: 'single' | 'multiple'
  isUpdateOnClose?: boolean
}

const CustomizedTagSelector: FunctionComponent<CustomizedTagSelectorProps> = ({
  mode,
  customizedTagsNames,
  onVisibleChange,
  onValuesChange,
  onSearchChange,
  children,
  isSearchable = false,
  isUpdateOnClose = false,
}) => {
  const { data: customizedTags = [] } = useFetchCustomizedTagListQuery()

  const [localCustomizedTags, setLocalCustomizedTags] = useState<
    string | undefined
  >(customizedTagsNames)

  const handleOnValuesChange = (newCustomizedTagsNames?: string): void => {
    setLocalCustomizedTags(newCustomizedTagsNames)
    if (!isUpdateOnClose) {
      onValuesChange(newCustomizedTagsNames)
    }
  }

  const handleOpenChange = (visible: boolean): void => {
    if (!visible && isUpdateOnClose) {
      onValuesChange(localCustomizedTags)
    }

    onVisibleChange?.(visible)
  }

  useEffect(() => {
    setLocalCustomizedTags(customizedTagsNames)
  }, [customizedTagsNames])

  const SelectArea =
    mode === 'multiple' ? (
      <CustomizedContentMultiMode
        customizedTagsNames={localCustomizedTags}
        data={customizedTags}
        isSearchable={isSearchable}
        onSearchChange={onSearchChange}
        onValuesChange={handleOnValuesChange}
      />
    ) : (
      <CustomizedTagContentSingleMode
        customizedTagsName={localCustomizedTags}
        data={customizedTags}
        onValuesChange={handleOnValuesChange}
      />
    )

  return (
    <StyledPopover
      destroyTooltipOnHide
      content={SelectArea}
      getPopupContainer={(triggerNode): HTMLElement => triggerNode}
      placement='bottomLeft'
      trigger='click'
      onOpenChange={handleOpenChange}
    >
      {children}
    </StyledPopover>
  )
}

export const OptionItem = styled.div<{ $isActive?: boolean }>`
  display: flex;
  justify-content: space-between;
  label {
    padding: 8px 12px;
  }
  cursor: pointer;
  background-color: ${({ $isActive, theme }): string =>
    $isActive ? '#fcf4f3' : theme.colors.background.white};
  color: ${({ $isActive, theme }): string =>
    $isActive ? '#E25242' : theme.colors.text.primary};

  :hover {
    background-color: #fcf4f3;
  }
`

export const OptionGroup = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50%;
  height: 275px;
  overflow-y: auto;
  :not(:last-child) {
    border-right: 1px solid
      ${({ theme }): string => theme.colors.border.divider};
  }
  svg {
    align-self: center;
    color: ${({ theme }): string => theme.colors.base.grey.grey3};
    width: 10px;
    height: 10px;
  }
`

export const OptionGroupListWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
`

const StyledPopover = styled(Popover)`
  .ant-popover {
    width: 510px;
  }
`
export default CustomizedTagSelector
