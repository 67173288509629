import { Input } from 'antd'
import { noop } from 'lodash-es'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useInput } from 'react-hanger'
import { useDebounce } from 'react-use'
import styled from 'styled-components'
import { useLazyQuickSearchWithExploreQuery } from '@/api/workspace-api/search-api'
import useKolAutocompleteOptions, {
  KolAutocompleteOptionType,
} from '@/components/search/hooks/use-kol-autocomplete-options'
import { UNLIMITED_COUNTRY_CODE } from '@/constants/country-code'
import { Page } from '@/hooks/use-authorization/constants'
import getPage from '@/hooks/use-authorization/get-page'
import routes from '@/hooks/use-authorization/routes'
import useHandleInputComposition from '@/hooks/use-handle-input-composition'
import { usePermissions } from '@/hooks/use-permissions'
import useSearch from '@/hooks/use-search'
import { useIntl } from '@/i18n/hooks/use-intl'
import { StyledAntdAutoComplete } from '@/types/antd/styled-antd'
import {
  QuickSearchKOL,
  QuickSearchKolFromIqData,
  KolSource,
  getKolSourceForAmplitudeEvent,
} from '@/types/api/search'
import { ampli } from '@/utils/ampli'
import logger from '@/utils/logger'
import { openTab } from '@/utils/routes/open-tab'

const { Search } = Input
export const SearchKeyword: FunctionComponent = () => {
  const router = useRouter()
  const pathname = usePathname()
  const { formatMessage } = useIntl()
  const [dataSource, setDataSource] = useState<
    (QuickSearchKOL | QuickSearchKolFromIqData)[]
  >([])
  const [open, setOpen] = useState(false)
  const { compositionEnd, handleInputComposition } = useHandleInputComposition(
    (): void => {
      handleSearchDefault()
    },
  )
  const keyword = useInput('')
  const [quickSearch, { isFetching: searching }] =
    useLazyQuickSearchWithExploreQuery()

  const { getKolAutocompleteOptions } = useKolAutocompleteOptions()
  const { withGuestPermission } = usePermissions()

  const displaySearch = !withGuestPermission
  const page = pathname ? getPage(pathname) : undefined
  const isNotSpecificPages = page
    ? ![Page.KolAssetCatalog, Page.Search].includes(page)
    : false
  const display = displaySearch && isNotSpecificPages
  const { handleSearch, kolQueryParams } = useSearch()
  const countryCode = kolQueryParams.country_code ?? UNLIMITED_COUNTRY_CODE

  const [, cancel] = useDebounce(
    async () => {
      try {
        cancel()
        if (keyword.value && compositionEnd) {
          setOpen(true)
          const res = await quickSearch({
            keyword: keyword.value,
          }).unwrap()
          ampli.quickSearchForKol({
            keyword: keyword.value,
            kolResult: res.data.map((kol) => kol.uuid ?? ''),
            kolNames: res.data.map((kol) => kol.name[0] ?? ''),
            dataSources: res.data.map((kol) =>
              getKolSourceForAmplitudeEvent(kol.source),
            ),
            isNewToPlatforms: res.data.map(
              (kol) => kol.source !== KolSource.DataCenter,
            ),
            countryCode,
          })
          setDataSource(res.data)

          return noop()
        }
      } catch (e) {
        setDataSource([])
      }
    },
    500,
    [keyword, compositionEnd],
  )

  const handleSearchDefault = useCallback((): void => {
    keyword.setValue(keyword.value)
    handleSearch({
      keyword: keyword.value,
      from: 'header',
    })
  }, [handleSearch, keyword])

  const handleClickCustomizedTagName = useCallback(
    (customizedTagName: string): void => {
      handleSearch({
        customized_tags_names: customizedTagName,
        from: 'header',
      })
    },
    [handleSearch],
  )

  const handleSelectOption = async (
    _,
    option: KolAutocompleteOptionType,
  ): Promise<void> => {
    switch (option.type) {
      case 'keyword':
        setOpen(false)

        ampli.chooseSearchResult({
          resultType: 'generalSearch',
          dataSource: 'DC',
          isNewToPlatform: false,
        })
        handleSearchDefault()
        break
      case 'customizedTag':
      case 'loading':
        keyword.setValue(keyword.value)
        break
      case 'kol':
        try {
          setOpen(false)

          keyword.setValue('')
          ampli.chooseSearchResult({
            resultType: 'kol',
            isNewToPlatform: option.kol.source !== KolSource.DataCenter,
            kolName: option.kol.name[0],
            dataSource: getKolSourceForAmplitudeEvent(option.kol.source),
          })

          if (option.kol.source === KolSource.IqData) {
            await openTab(
              routes.kolCreate.stringifyUrl(
                {},
                {
                  url: option.kol.links[0].url,
                  from: 'Quick Search',
                  isNewToPlatform: true,
                  dataSource: getKolSourceForAmplitudeEvent(option.kol.source),
                },
              ),
            )
            return
          }

          await openTab(
            routes.kolId.stringifyUrl(
              {
                kolId: option.key,
              },
              { from: 'Quick Search' },
            ),
          )
        } catch (error) {
          logger.error(error)
        }
    }
  }

  const handleInput = (value: string): void => {
    keyword.setValue(value)
    if (value === '') {
      setOpen(false)
    }
  }

  const dropdownRender = useMemo(() => {
    return getKolAutocompleteOptions(
      searching,
      keyword.value,
      dataSource,
      handleClickCustomizedTagName,
    )
  }, [
    dataSource,
    getKolAutocompleteOptions,
    handleClickCustomizedTagName,
    keyword.value,
    searching,
  ])

  const onFocus = (): void => {
    if (keyword.value !== '') {
      setOpen(true)
    }
    ampli.trySearchForKol({ path: router.asPath })
  }

  return display ? (
    <SearchInputContainer id='SearchInputContainer'>
      <StyledAutoComplete
        dropdownStyle={{ width: 400 }}
        getPopupContainer={(): HTMLElement =>
          document.getElementById('SearchInputContainer') ?? document.body
        }
        open={open}
        options={dropdownRender}
        popupMatchSelectWidth={false}
        value={keyword.value}
        onBlur={(): void => setOpen(false)}
        onChange={handleInput}
        onFocus={onFocus}
        onSelect={handleSelectOption}
      >
        <Search
          loading={searching}
          placeholder={formatMessage({
            id: 'general:navbar_search_placeholder',
          })}
          size='small'
          {...handleInputComposition}
          onSearch={handleSearchDefault}
        />
      </StyledAutoComplete>
    </SearchInputContainer>
  ) : (
    <div />
  )
}

export const SearchInputContainer = styled.span`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }

  .ant-skeleton-title {
    margin: 12px 0 10px 0 !important;
  }

  .ant-input-wrapper {
    button {
      border-left: none;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }

    input {
      border-right: none;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    svg:hover {
      color: ${({ theme }): string => theme.colors.brand.primary};
    }

    :hover {
      button,
      input {
        border-color: #ff7161 !important;
      }
    }
  }

  .ant-select-item {
    padding: 0;
  }

  .ant-select-dropdown {
    padding: 0;
  }
`

const StyledAutoComplete = styled(StyledAntdAutoComplete)`
  .anticon-search {
    color: ${({ theme }): string => theme.colors.primary};
  }
`
