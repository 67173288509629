import isNil from 'lodash-es/isNil'
import omitBy from 'lodash-es/omitBy'

export const isNilOrEmptyString = (value: unknown): boolean => {
  return isNil(value) || value === ''
}
const removeNilOrEmptyString = <T extends object>(params: T): Partial<T> => {
  return omitBy<T>(params, isNilOrEmptyString)
}

export default removeNilOrEmptyString
