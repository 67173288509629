import { z } from 'zod'
import { countryCodeSchema } from '@/types/schema/country-code-schema'
import { platformCodeSchema } from '@/types/schema/platform/platform-code-schema'

export const linkListPlatformsSchema = platformCodeSchema.extract([
  'instagram',
  'facebook',
  'youtube',
])
export type LinkListPlatforms = z.infer<typeof linkListPlatformsSchema>

export const linkListCountryCodesSchema = countryCodeSchema.extract([
  'tw',
  'jp',
  'hk',
  'my',
  'th',
  'vn',
  'sg',
])
export type LinkListCountryCode = z.infer<typeof linkListCountryCodesSchema>

export const billboardMultiDimensionParamsSchema = z.object({
  platform: linkListPlatformsSchema
    .optional()
    .default(platformCodeSchema.enum.instagram),
  country: linkListCountryCodesSchema
    .optional()
    .default(countryCodeSchema.enum.tw),
})
export type BillboardMultiDimensionParams = z.infer<
  typeof billboardMultiDimensionParamsSchema
>

export const billboardMultiDimensionListSearchParamsSchema = z.object({
  lang: z.string().optional(),
})

export const billboardStatisticSchema = z.enum([
  'community-volume',
  'engagement-rate',
  'view-rate',
])
export type BillboardStatistic = z.infer<typeof billboardStatisticSchema>
