import { z } from 'zod'

export const platformCodeSchema = z.enum([
  'all',
  'blog',
  'facebook',
  'instagram',
  'other',
  'tiktok',
  'youtube',
  'twitter',
])

export type PlatformCode = z.infer<typeof platformCodeSchema>
