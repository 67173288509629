import { z } from 'zod'

export const userMetadataSchema = z.object({
  version_no: z.string(),
  completed_onboarding: z.boolean(),
  pricing_announcement_date: z.enum(['2024/08/27']).optional(),
  read_202408_pricing_announcement: z.boolean(),
  kol_ai_search_mode: z.boolean().optional(),
  is_world_kol_tour_read: z.boolean().optional(),
})

export const partialUserMetadataSchema = userMetadataSchema.partial()

export type PartialUserMetadata = z.infer<typeof partialUserMetadataSchema>

export type UserMetadata = z.infer<typeof userMetadataSchema>
