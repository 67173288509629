import { theme, Icon } from '@buggy/shared'
import { ConfigProvider, Popover, Divider, Select, Flex, Space } from 'antd'
import {
  useMemo,
  useState,
  type FunctionComponent,
  type ReactNode,
} from 'react'
import styled from 'styled-components'
import { NoData } from '@/components/kol/detail/no-data'
import {
  type AllCountryCode,
  defaultPriorityCountries,
  allCountries,
  COUNTRY_I18N_MAP,
} from '@/constants/country-code'
import { useIntl } from '@/i18n/hooks/use-intl'
import { GeneralOption } from '@/types/search-types'

interface AllCountrySelectProps {
  disabled?: boolean
  isSecondaryGroupDisabled?: boolean
  value?: AllCountryCode
  onChange?: (value?: AllCountryCode) => void
  onClose?: () => void
  defaultPriorityOptions?: GeneralOption[]
  isShowCurrency?: boolean
  children: ReactNode
}

const AllCountrySelect: FunctionComponent<AllCountrySelectProps> = ({
  disabled = false,
  isSecondaryGroupDisabled = false,
  value,
  onChange,
  onClose,
  defaultPriorityOptions,
  isShowCurrency = false,
  children,
}) => {
  const { formatMessage } = useIntl()

  const [isVisible, setIsVisible] = useState(false)
  const [isSelectOpen, setIsSelectOpen] = useState(true)

  // priorityOptions 由 defaultPriorityOptions 或 defaultPriorityCountries 產生
  const priorityOptions = useMemo(() => {
    return (
      defaultPriorityOptions ??
      defaultPriorityCountries.map((country) => {
        const countryName = formatMessage({
          id: COUNTRY_I18N_MAP[country.value],
        })
        const countryCurrency = country.currency ? `(${country.currency})` : ''

        return {
          label: isShowCurrency
            ? `${countryName} ${countryCurrency}`
            : countryName,
          value: country.value,
        }
      })
    )
  }, [defaultPriorityOptions, formatMessage, isShowCurrency])

  const groupOptions = useMemo(() => {
    return [
      {
        label: '',
        title: '',
        options: priorityOptions,
      },
      {
        label: (
          <Flex align='center' style={{ height: '12px' }}>
            <Divider />
          </Flex>
        ),
        title: '',
        options: allCountries
          .map((country) => {
            const countryName = formatMessage({
              id: COUNTRY_I18N_MAP[country.value],
            })
            const countryCurrency = country.currency
              ? `(${country.currency})`
              : ''

            return {
              label: isShowCurrency
                ? `${countryName} ${countryCurrency}`
                : countryName,
              value: country.value,
              disabled: isSecondaryGroupDisabled,
            }
          })
          .filter((country) => {
            return !priorityOptions.some(
              (priorityCountry) => priorityCountry.value === country.value,
            )
          }),
      },
    ]
  }, [priorityOptions, formatMessage, isShowCurrency, isSecondaryGroupDisabled])

  const handleSelect = (value: AllCountryCode): void => {
    onChange?.(value)
    setIsSelectOpen(false)
    setTimeout(() => setIsVisible(false), 100) // 延遲關閉 Popover, 避免關閉 popover 後，Select 還停留在畫面上
  }

  const handleOnOpenChange = (open: boolean): void => {
    setIsSelectOpen(open)

    if (!open) {
      onClose?.()

      setTimeout(() => setIsVisible(false), 150) // 延遲關閉 Popover, 避免關閉 popover 後，Select 還停留在畫面上
      return
    }

    setIsVisible(true)
  }

  return (
    <StyledPopover
      destroyTooltipOnHide
      content={
        <ConfigProvider
          theme={{
            components: {
              Select: {
                boxShadowSecondary: 'none',
                optionHeight: 0,
                optionPadding: 0,
                controlPaddingHorizontal: 0,
                controlPaddingHorizontalSM: 0,
                paddingXXS: 2,
                paddingXS: 2,
                paddingSM: 2,
              },
            },
          }}
        >
          <StyledSelect
            showSearch
            defaultValue={value}
            disabled={disabled}
            dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
            getPopupContainer={(triggerNode): HTMLElement => triggerNode}
            listHeight={250}
            notFoundContent={<NoData />}
            open={isSelectOpen}
            optionFilterProp='label'
            optionRender={(option) => (
              <Option
                $isDisabled={option.data.disabled}
                $isSelected={option.value === value}
              >
                <div>{option.label}</div>
                {option.value === value && (
                  <Icon
                    color={theme.colors.brand.primary}
                    fontSize={16}
                    name='check'
                  />
                )}
              </Option>
            )}
            options={groupOptions}
            placeholder={formatMessage({ id: 'search:filter_search' })}
            onChange={handleSelect}
          />
        </ConfigProvider>
      }
      getPopupContainer={(triggerNode): HTMLElement => triggerNode}
      open={isVisible}
      placement='bottomRight'
      trigger='click'
      onOpenChange={handleOnOpenChange}
    >
      {children}
    </StyledPopover>
  )
}

const StyledPopover = styled(Popover)`
  .ant-popover {
    width: 220px;
    height: 320px;
    background-color: ${({ theme }): string => theme.colors.background.white};
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

    .ant-popover-inner {
      box-shadow: none;
      padding-bottom: 0;

      .ant-select-selector {
        padding 4px 0;
      }
    }
  }
`

const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selector {
    padding: 4px 0;
  }

  .ant-select-selection-placeholder {
    padding-inline-start: 12px !important;
  }

  .ant-select-selection-item {
    padding: 0 12px !important;
  }

  .ant-select-arrow {
    right: 22px !important;
  }

  input {
    padding: 0 12px !important;
  }
`

const Option = styled(Space)<{ $isSelected: boolean; $isDisabled?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 8px 12px;
  line-height: 36px;
  height: 36px;
  font-size: 14px;
  color: ${({ theme, $isSelected, $isDisabled = false }): string => {
    if ($isDisabled) {
      return theme.colors.base.grey.grey4
    }

    if ($isSelected) {
      return theme.colors.brand.primary
    }

    return theme.colors.text.secondary
  }};

  border-radius: 5px;

  svg {
    vertical-align: middle;
  }
`

export default AllCountrySelect
