import {
  type BaseQueryFn,
  FetchArgs,
  type FetchBaseQueryError,
  type FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/react'
import { isString } from 'lodash-es'
import isFunction from 'lodash-es/isFunction'
import { ExecutableRequest } from '@/types/api'

type ExecutableQuery = BaseQueryFn<
  string | (FetchArgs & ExecutableRequest),
  unknown,
  FetchBaseQueryError,
  unknown,
  FetchBaseQueryMeta
>

const onFullFilledQuery: (baseQuery: ExecutableQuery) => ExecutableQuery =
  (baseQuery: ExecutableQuery) => async (args, api, extraOptions) => {
    if (isString(args)) {
      return baseQuery(args, api, extraOptions)
    }

    const returnValue = await baseQuery(args, api, extraOptions)

    if (isFunction(args.onQueryFulfilled)) {
      args.onQueryFulfilled()
    } else if (isFunction(args.onQueryFulfilled?.then)) {
      await args.onQueryFulfilled
    }

    return returnValue
  }

export default onFullFilledQuery
