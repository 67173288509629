import { Dropdown, Menu } from 'antd'
import noop from 'lodash-es/noop'
import { useRouter } from 'next/router'
import { stringifyUrl } from 'query-string'
import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useQueryParams } from 'use-query-params'
import { AppContext } from '@/api/executor/app-context'
import { invalidateUserInfoAction } from '@/api/user-api'
import { H6 } from '@/components/common/typography'
import { FeatherIcon, Icon } from '@/components/custom-icon'
import { useAuth } from '@/hooks/use-auth'
import useIsPublicDetailPage from '@/hooks/use-is-public-detail-page'
import { SupportedLanguages } from '@/i18n/config'
import { getLanguage, LanguageDict, languages } from '@/i18n/languages'
import { ampli } from '@/utils/ampli'
import { goToPage } from '@/utils/routes/go-to-page'
import { NonNullStringParam } from '@/utils/use-query-params'

interface LangDropdownProps {
  language?: LanguageDict
  className?: string
}

const LangDropdown: FunctionComponent<LangDropdownProps> = ({
  className = '',
  language,
}) => {
  const dispatch = useDispatch()
  const router = useRouter()

  const [{ from, identity }, setQuery] = useQueryParams({
    from: NonNullStringParam,
    identity: NonNullStringParam,
    lang: NonNullStringParam,
  })
  const { userInfo } = useAuth()
  const isPublicKolDetailPage = useIsPublicDetailPage()

  const selectLanguage = (code: SupportedLanguages): void => {
    if (userInfo) {
      AppContext.ApiExecutor.updateUser({ language: code })
        .then(() => {
          dispatch(invalidateUserInfoAction)
          ampli.changeLanguage({ language: code })

          return noop()
        })
        .catch((error) => console.warn(error))
      if (isPublicKolDetailPage) {
        setQuery({ from, identity, lang: code })
      }
    } else {
      const path = isPublicKolDetailPage
        ? `${location.origin}${location.pathname}`
        : location.pathname

      goToPage(
        stringifyUrl({
          url: path,
          query: {
            ...router.query,
            lang: code,
            identity,
            from,
          },
        }),
      ).then(noop)
    }
  }

  const menu = (
    <Menu>
      {languages.map((lang, index) => (
        <Menu.Item key={index}>
          <ItemWrapper onClick={(): void => selectLanguage(lang.code)}>
            {lang.label}
          </ItemWrapper>
        </Menu.Item>
      ))}
    </Menu>
  )

  if (userInfo && !language) {
    return (
      <Dropdown
        className={className}
        dropdownRender={() => menu}
        trigger={['click']}
      >
        <UserItem onClick={(e): void => e.preventDefault()}>
          <GlobeIcon type='elIconGlobe' />
          <H6>{getLanguage(userInfo.language).label}</H6>
          <FeatherIcon type='chevron-down' />
        </UserItem>
      </Dropdown>
    )
  }

  if (language) {
    return (
      <Dropdown className={className} dropdownRender={() => menu}>
        <UserItem onClick={(e): void => e.preventDefault()}>
          <Icon style={{ fontSize: '16px' }} type='elIconGlobe' />
          <H6>{language.label}</H6>
          <FeatherIcon type='chevron-down' />
        </UserItem>
      </Dropdown>
    )
  }

  return null
}

export default styled(LangDropdown)``

const GlobeIcon = styled(Icon)`
  font-size: 16px;
  flex: 1 0 auto;
`

const UserItem = styled.div`
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }): string => theme.colors.text.primary};

  h6 {
    flex: 1 0 auto;
  }

  ${GlobeIcon} {
    margin-right: 8px;
  }

  ${FeatherIcon} {
    color: ${({ theme }): string => theme.colors.gray7};
    margin-left: 8px;
  }
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  height: 35px;

  svg {
    margin-right: 5px;
  }

  &:hover {
    color: ${({ theme }): string => theme.colors.brand.secondary};
  }
`
