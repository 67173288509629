import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { useFetchUserStatusQuery } from '@/api/user-api'
import Countdown from '@/components/common/countdown'
import routes from '@/hooks/use-authorization/routes'
import { useIntl } from '@/i18n/hooks/use-intl'
import { ampli } from '@/utils/ampli'

export const TrialCounterHeight = 36

const TrialCounter: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const { asPath } = useRouter()
  const { data: userStatus } = useFetchUserStatusQuery()
  const expireAt = userStatus?.currentWorkspace.workspaceAuth?.expireAt || ''

  const handleClickTrial: VoidFunction = () => {
    ampli.clickWorkspacePlan({ path: asPath, clickPlanSource: 'Header' })
  }

  return (
    <Countdown
      expireAt={expireAt}
      render={(countDownTime): ReactNode => (
        <TrialWrapper
          href={routes.workspacePlan.stringifyUrl({})}
          onClick={handleClickTrial}
        >
          {formatMessage(
            { id: 'account:premium_trial_timeout' },
            {
              day: countDownTime.days,
              hour: countDownTime.hours,
              minute: countDownTime.minutes,
              cta: (
                <CtaLink>
                  {formatMessage({
                    id: 'account:premium_trial_timeout_call_to_action',
                  })}
                </CtaLink>
              ),
            },
          )}
        </TrialWrapper>
      )}
    />
  )
}

export default TrialCounter

const CtaLink = styled.strong`
  text-decoration: underline;
`

const TrialWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  background: ${({ theme }): string => theme.colors.base.gold.gold5};
  color: ${({ theme }): string => theme.colors.plan.premium};
  font-size: 14px;
  line-height: 20px;
  height: ${TrialCounterHeight}px;

  &:hover {
    color: ${({ theme }): string => theme.colors.plan.premium};
  }
`
