export enum KolSortOption {
  Recommend = 'recommend',
  FollowerCount = 'followerCount',
  MaxFansUpRate3Month = 'maxFansUpRate3Month',
  MaxInteractiveRate3Month = 'maxInteractiveRate3Month',
  MaxAvgPvRate3Month = 'maxAvgPvRate3Month',
  MatchedPost = 'matchedPost',
}

export const defaultKolSortOption = KolSortOption.Recommend
