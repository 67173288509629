import { z } from 'zod'

export const platformShortcodeSchema = z.enum([
  'all',
  'blog',
  'fb',
  'fb_person',
  'ig',
  'other',
  'tiktok',
  'yt',
  'twitter',
])
