import dayjs from 'dayjs'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useFetchUserStatusQuery } from '@/api/user-api'
import UnsafeNewTabLink from '@/components/public-page/footer/components/unsafe-new-tab-link'
import { SafeNewTabLink } from '@/components/safe-new-tab-link'
import { CountryCode } from '@/constants/country-currency'
import { getLocaleRegion, useI18n } from '@/hooks/use-i18n'
import { useIntl } from '@/i18n/hooks/use-intl'
import { StyledFooter } from '@/types/antd/styled-antd'
import env from '@/utils/env'

export const FOOTER_HEIGHT = 194

const IrmFooter: FunctionComponent<{ isCollapsed: boolean }> = ({
  isCollapsed,
}) => {
  const { data: workspaceStatus } = useFetchUserStatusQuery()
  const { formatMessage } = useIntl()
  const { locale } = useI18n()
  const region = getLocaleRegion(locale)

  const isJapan = locale === 'ja'
  const displayTaxId =
    workspaceStatus?.currentWorkspace?.countryCode === CountryCode.TW

  const getRootUrl = (url: string): string => {
    const urls = [getLocaleRegion(locale), url].filter((_region) => _region)
    return urls.join('/')
  }

  const items = [
    {
      text: 'KOL Radar',
      url: `${env.LANDING_URL}/${region}`,
    },
    {
      text: formatMessage({ id: 'general:footer_text_terms' }),
      url: `${env.LANDING_URL}/${getRootUrl('terms')}`,
    },
    {
      text: formatMessage({ id: 'general:footer_text_privacy' }),
      url: `${env.LANDING_URL}/${getRootUrl('privacy')}`,
    },
    ...(isJapan
      ? [
          {
            text: formatMessage({
              id: 'general:specified_commercial_transactions_law',
            }),
            url: `${env.LANDING_URL}/${getRootUrl(
              'specified-commercial-transactions-law',
            )}`,
          },
        ]
      : []),
    {
      text: formatMessage({ id: 'general:footer_text_contact' }),
      url: `${env.LANDING_URL}/${getRootUrl('contact')}`,
    },
  ]

  return (
    <FooterWrapper isCollapsed={isCollapsed}>
      <StyleFooter>
        <Wrapper>
          <CopyRight>
            {items.map(({ text, url }) => (
              <SafeNewTabLink href={url} key={text}>
                {text}
              </SafeNewTabLink>
            ))}
          </CopyRight>
          <CopyRight>
            {displayTaxId && (
              <>
                <div>
                  {formatMessage({ id: 'general:ikala_company_information' })}
                </div>
                <Divider />
                <div>
                  {formatMessage({ id: 'general:ikala_company_tax_id' })}
                </div>
                <Divider />
              </>
            )}
            <div>
              Copyright © {dayjs().format('YYYY')}{' '}
              <UnsafeNewTabLink href='https://ikala.ai/'>
                iKala
              </UnsafeNewTabLink>{' '}
              All Rights Reserved.
            </div>
          </CopyRight>
        </Wrapper>
      </StyleFooter>
    </FooterWrapper>
  )
}

export default IrmFooter

export const FooterWrapper = styled.div<{ isCollapsed: boolean }>`
  margin: 0 0 0 ${(props): number => (props.isCollapsed ? 80 : 200)}px;
`

const StyleFooter = styled(StyledFooter)`
  padding: 90px 0 60px;
  height: ${FOOTER_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f7f7;
`
const Divider = styled.span.attrs({ children: ' | ' })`
  margin: 0 4px;
  color: ${({ theme }): string => theme.colors.base.grey.grey3};
`

const CopyRight = styled.div`
  color: ${({ theme }): string => theme.colors.text.secondary};

  a {
    line-height: 24px;
    color: ${({ theme }): string => theme.colors.text.secondary};

    &:hover {
      color: ${({ theme }): string => theme.colors.brand.primary};
    }
  }

  &:first-of-type {
    > a:not(:last-child) {
      ::after {
        content: ' | ';
        margin: 0 4px;
        color: ${({ theme }): string => theme.colors.base.grey.grey3};
      }
    }
  }

  div {
    display: inline;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    text-align: center;

    div {
      display: block;
    }
    &:first-child {
      margin-bottom: 24px;
    }
    ${Divider} {
      display: none;
    }
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
