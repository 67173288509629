import { isEqual } from 'lodash-es'
import isUndefined from 'lodash-es/isUndefined'
import {
  EVENT_FOLLOWER_COUNT_RANGE_RECORD,
  FOLLOWER_COUNT_RANGE_RECORD,
} from '@/components/kol/common/follower-level-selector'
import { Condition } from '@/types/api/search'
import { SearchForKolProperties } from '@/utils/ampli'

const mapEventPropertyFilterFollowerRange = ({
  follower_start_from,
  follower_end_to,
}: Pick<Condition, 'follower_start_from' | 'follower_end_to'>):
  | SearchForKolProperties['filterFollowerRange']
  | undefined => {
  // level8 follower_end_to is undefined from query
  if (
    follower_start_from ===
      FOLLOWER_COUNT_RANGE_RECORD.level8.followerStartFrom &&
    isUndefined(follower_end_to)
  ) {
    return EVENT_FOLLOWER_COUNT_RANGE_RECORD.level8
  }

  const level = Object.keys(FOLLOWER_COUNT_RANGE_RECORD).find((key) =>
    isEqual(FOLLOWER_COUNT_RANGE_RECORD[key], {
      followerStartFrom: follower_start_from,
      followerEndTo: follower_end_to,
    }),
  )

  return level ? EVENT_FOLLOWER_COUNT_RANGE_RECORD[level] : undefined
}

export default mapEventPropertyFilterFollowerRange
