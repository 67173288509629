import { z } from 'zod'

export const countryCodeSchema = z.enum([
  'tw',
  'hk',
  'jp',
  'cn',
  'my',
  'sg',
  'th',
  'vn',
  'kr',
  'id',
  'ph',
  'in',
  'us',
  'ca',
  'au',
])
