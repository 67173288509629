import { ApiVersion } from '@/api/utils/default-api-version-base-query-wrapper'

export const WORKSPACE_API_URL_PREFIX = '/workspaces'
export const WORKSPACE_ID_PLACEHOLDER = '/:workspaceId'

const getWorkspaceApiUrl = (
  remainingPath: string,
  version: ApiVersion = 'v2',
): string => {
  const path = remainingPath.startsWith('/')
    ? remainingPath
    : `/${remainingPath}`
  return [
    `/${version}`,
    WORKSPACE_API_URL_PREFIX,
    WORKSPACE_ID_PLACEHOLDER,
    path,
  ].join('')
}

export default getWorkspaceApiUrl
