import { FollowerCountRangeGroup } from '@/components/kol/common/follower-level-selector'
import { I18nId } from '@/i18n/config'
import FollowerLevel from '@/types/schema/follower-level'

const followerRangeGroupI18nIdMap: Record<FollowerCountRangeGroup, I18nId> = {
  small: 'general:small_follower_range_group',
  medium: 'general:medium_follower_range_group',
  large: 'general:large_follower_range_group',
}

const followerLevelInfluencerI18nIdMap: Record<FollowerLevel, I18nId> = {
  level1: 'general:tiny_influencer',
  level2: 'general:micro_influencer',
  level3: 'general:small_influencer',
  level4: 'general:small_medium_influencer',
  level5: 'general:medium_influencer',
  level6: 'general:medium_large_influencer',
  level7: 'general:large_influencer',
  level8: 'general:mega_influencer',
}

const followerLevelInfluencerRangeI18nIdMap: Record<FollowerLevel, I18nId> = {
  level1: 'general:tiny_influencer_range',
  level2: 'general:micro_influencer_range',
  level3: 'general:small_influencer_range',
  level4: 'general:small_medium_influencer_range',
  level5: 'general:medium_influencer_range',
  level6: 'general:medium_large_influencer_range',
  level7: 'general:large_influencer_range',
  level8: 'general:mega_influencer_range',
}

const followerLevelRangeI18nIdMap: Record<FollowerLevel, I18nId> = {
  level1: 'general:tiny_level_range',
  level2: 'general:micro_level_range',
  level3: 'general:small_level_range',
  level4: 'general:small_medium_level_range',
  level5: 'general:medium_level_range',
  level6: 'general:medium_large_level_range',
  level7: 'general:large_level_range',
  level8: 'general:mega_level_range',
}
const followerRangeI18nIdMap: Record<FollowerLevel, I18nId> = {
  level1: 'general:tiny_range',
  level2: 'general:micro_range',
  level3: 'general:small_range',
  level4: 'general:small_medium_range',
  level5: 'general:medium_range',
  level6: 'general:medium_large_range',
  level7: 'general:large_range',
  level8: 'general:mega_range',
}

export {
  followerLevelInfluencerRangeI18nIdMap,
  followerLevelInfluencerI18nIdMap,
  followerLevelRangeI18nIdMap,
  followerRangeI18nIdMap,
  followerRangeGroupI18nIdMap,
}
