import * as process from 'process'
import {
  StringifiableRecord,
  StringifyOptions,
  stringifyUrl,
} from 'query-string'
import { useCallback } from 'react'
import { CountryCode } from '@/constants/country-currency'
import { Page } from '@/hooks/use-authorization/constants'
import { getLocaleRegion, useI18n } from '@/hooks/use-i18n'
import env from '@/utils/env'

interface UseUrlControlProps {
  getRootUrl: (path?: string) => string
  getAppUrl: (
    path?: string,
    query?: StringifiableRecord,
    options?: StringifyOptions,
  ) => string
  getPageUrl: (
    page: Page,
    query?: StringifiableRecord,
    options?: StringifyOptions,
  ) => string
}

const useUrlControl = (): UseUrlControlProps => {
  const { locale } = useI18n()
  const country = getLocaleRegion(locale) || CountryCode.TW

  const getRootUrl = useCallback(
    (path?: string): string => {
      if (!path) {
        return env.LANDING_URL
      }

      if (country && country !== CountryCode.TW) {
        return `${env.LANDING_URL}/${country}/${path}`
      }

      return `${env.LANDING_URL}/${path}`
    },
    [country],
  )

  const getAppUrl: UseUrlControlProps['getAppUrl'] = (path, query, options) => {
    return stringifyUrl(
      {
        url: path
          ? `${process.env.NEXT_PUBLIC_APP_URL}/${path}`
          : process.env.NEXT_PUBLIC_APP_URL,
        query,
      },
      options,
    )
  }

  const getPageUrl: UseUrlControlProps['getPageUrl'] = useCallback(
    (page, query, options) => {
      return stringifyUrl(
        {
          url: `${env.APP_URL}${page}`,
          query,
        },
        options,
      )
    },
    [],
  )

  return { getRootUrl, getAppUrl, getPageUrl }
}

export default useUrlControl
