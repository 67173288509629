import orderBy from 'lodash-es/orderBy'

export interface CustomizedTagNameSearchData {
  name: string
  localizedName: string
  routerName: string
}

const searchCustomizedTagNames = (
  keyword: string,
  tags: CustomizedTagNameSearchData[],
): string[] => {
  const keywordList = keyword.split(' ').filter((word) => word !== '')
  const scores = tags
    .map(({ name, localizedName }) => {
      if (localizedName === keyword) {
        return { name, score: Infinity }
      }

      const localizedNameList = localizedName.split(' ')

      const score = localizedNameList.reduce((score, name) => {
        for (const word of keywordList) {
          if (name.toLocaleLowerCase().includes(word.toLocaleLowerCase())) {
            return score + 1
          }
        }
        return score
      }, 0)

      return { name, score }
    })
    .filter((item) => item.score !== 0)
  return orderBy(scores, ['score'], ['desc']).map((item) => item.name)
}

export default searchCustomizedTagNames
