import { z } from 'zod'

const envSchema = z.object({
  API_BASE_URL: z.string(),
  APP_URL: z.string(),
  DEVELOPMENT_MODE: z.boolean(),
  LANDING_URL: z.string(),
  PUBLIC_CLIENT_URL: z.string(),
  GOOGLE_ADS_ID: z.string(),
  GOOGLE_ADS_ACCOUNT_CREATED_EVENT_ID: z.string(),
  GOOGLE_ADS_TRIAL_EVENT_ID: z.string(),
  GOOGLE_ADS_PURCHASE_SUBSCRIPTION_EVENT_ID: z.string(),
  STRIPE_PUBLISHABLE_KEY: z.string(),
  K6RSATELLITE_API_BASE_URL: z.string(),
  PUBLIC_K6RSATELLITE_API_BASE_URL: z.string(),
  ASSETS_CATALOGS_ENABLED: z.boolean(),
})

const env = envSchema.parse({
  APP_URL: process.env.NEXT_PUBLIC_APP_URL,
  API_BASE_URL: process.env.NEXT_PUBLIC_NEXT_API_BASE_URL,
  DEVELOPMENT_MODE: process.env.NODE_ENV === 'development',
  LANDING_URL: process.env.NEXT_PUBLIC_LANDING_URL,
  PUBLIC_K6RSATELLITE_API_BASE_URL:
    process.env.NEXT_PUBLIC_PUBLIC_K6RSATELLITE_API_BASE_URL,
  PUBLIC_CLIENT_URL: process.env.NEXT_PUBLIC_PUBLIC_CLIENT_URL,
  GOOGLE_ADS_ID: process.env.NEXT_PUBLIC_GOOGLE_ADS_ID,
  GOOGLE_ADS_ACCOUNT_CREATED_EVENT_ID:
    process.env.NEXT_PUBLIC_GOOGLE_ADS_ACCOUNT_CREATED_EVENT_ID,
  GOOGLE_ADS_TRIAL_EVENT_ID: process.env.NEXT_PUBLIC_GOOGLE_ADS_TRIAL_EVENT_ID,
  GOOGLE_ADS_PURCHASE_SUBSCRIPTION_EVENT_ID:
    process.env.NEXT_PUBLIC_GOOGLE_ADS_PURCHASE_SUBSCRIPTION_EVENT_ID,
  STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  K6RSATELLITE_API_BASE_URL: process.env.NEXT_PUBLIC_K6RSATELLITE_API_BASE_URL,
  ASSETS_CATALOGS_ENABLED: process.env.NEXT_PUBLIC_VERSION === 'local-version',
})

export default env
