import { ChartRowData } from '@/types/schema/chart-data-schema'
import { Report, ReportContent } from '@/types/schema/report-schema'

const NAMESPACE = 'report'

interface StateReport {
  report?: Report
  chartData: {
    [section: string]: ChartRowData[]
  }
  allChartData: {
    [section: string]: ChartRowData[]
  }
}

interface SectionIsLoading {
  section: string
  isLoading: boolean
}

export type SummaryFilterSection =
  | 'score'
  | 'comment'
  | 'kol'
  | 'post'
  | 'platform'

interface SummaryFilter {
  kol: string[]
  platform: string[]
}

interface Tab {
  index: number
  id: number
}

interface SummaryFilterSettings {
  currentReportID: number | 'demo'
  filterByBoard: Record<SummaryFilterSection, SummaryFilter>
}

export type ReportMode = 'preview' | 'edit' | ''

export interface State {
  data: StateReport
  editGuestVisibility: {
    [key: string]: boolean
  }
  error: Error | null
  loading: boolean
  summaryFilter: SummaryFilterSettings
  editingReportContent?: ReportContent
  mode: ReportMode
}

export const ACTION_TYPES = {
  CLEAN_REPORT: `${NAMESPACE}#cleanReport`,
  CLEAN_REPORT_CONTENT: `${NAMESPACE}#cleanReportContent`,
  SET_ALL_CHART_DATA: `${NAMESPACE}#setAllChartData`,
  SET_CHART_DATA: `${NAMESPACE}#setChartData`,
  SET_IS_LOADING: `${NAMESPACE}#setIsLoading`,
  SET_REPORT: `${NAMESPACE}#setReport`,
  ADD_REPORT_KOLS: `${NAMESPACE}#addReportKOLs`,
  DELETE_REPORT_KOL: `${NAMESPACE}#deleteReportKOL`,
  SET_REPORT_CONTENT: `${NAMESPACE}#setReportContent`,
  REFRESH_LIST: `${NAMESPACE}#refreshList`,
  SET_SUMMARY_FILTER: `${NAMESPACE}#setSummaryFilter`,
  SET_MODE: `${NAMESPACE}#setMode`,
  SET_GUEST_VISIBILITY: `${NAMESPACE}#setGuestVisibility`,
  SET_VISIBILITY: `${NAMESPACE}#setVisibility`,
}

export interface ActionPayload {
  SET_ALL_CHART_DATA: {
    data: ChartRowData[]
  }
  SET_CHART_DATA: {
    section: SummaryFilterSection
    data: ChartRowData[]
  }
  SET_IS_LOADING: SectionIsLoading
  SET_REPORT: Report | undefined
  SET_REPORT_CONTENT: ReportContent
  SET_SUMMARY_FILTER: {
    currentReportID: number | 'demo'
    section: SummaryFilterSection
    data: SummaryFilter
  }
  SET_TAB: Tab
  ADD_REPORT_KOLS: any[]
  DELETE_REPORT_KOL: { id: number }
  SET_MODE: ReportMode
  SET_GUEST_VISIBILITY: { [key: string]: boolean }
  SET_VISIBILITY: { [key: string]: boolean }
}

export interface Action {
  REFRESH_LIST: {
    type: typeof ACTION_TYPES.REFRESH_LIST
  }
  SET_REPORT: {
    type: typeof ACTION_TYPES.SET_REPORT
    payload: ActionPayload['SET_REPORT']
  }
  SET_ALL_CHART_DATA: {
    type: typeof ACTION_TYPES.SET_ALL_CHART_DATA
    payload: ActionPayload['SET_ALL_CHART_DATA']
  }
}
