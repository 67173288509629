import { combineEpics } from 'redux-observable'
import campaignEpic from '@/store/epics/campaign-epic'
import campaignReportEpic from '@/store/epics/campaign-report-epic'
import chatroomEpic from '@/store/epics/chatroom-epic'
import irmEpic from '@/store/epics/irm-epic'
import socialAuthEpic from '@/store/epics/social-auth-epic'

/**
 * @deprecated
 */
const epics = combineEpics(
  ...chatroomEpic,
  ...socialAuthEpic,
  ...irmEpic,
  ...campaignEpic,
  ...campaignReportEpic,
)

export default epics
