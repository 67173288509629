import { uniqBy } from 'lodash-es'
import { Link } from '@/types/schema/kol-schema'
import { PlatformType } from '@/types/schema/report-schema'
import { getPlatformShortName } from '@/utils/get-platform-name'

const platformSortScore = {
  all: -1,
  ig: 0,
  fb: 1,
  yt: 2,
  tiktok: 3,
  blog: 4,
  twitter: 5,
  twitch: 6,
  weibo: 7,
  plurk: 8,
  others: 9,
}

const platformTypeSortScore = {
  [PlatformType.All]: -1,
  [PlatformType.Instagram]: 0,
  [PlatformType.Facebook]: 1,
  [PlatformType.YouTube]: 2,
  [PlatformType.TikTok]: 3,
  // blog: 4,
  [PlatformType.Twitter]: 5,
  // twitch: 6,
  // weibo: 7,
  // plurk: 8,
  [PlatformType.Other]: 9,
}

export function getMainPlatformsByLinks(links: Link[] = []): Link[] {
  return uniqBy(links, 'type').sort(
    ({ type: platform1 = 'others' }, { type: platform2 = 'others' }) => {
      const platform1Score = platformSortScore[platform1]
      const platform2Score = platformSortScore[platform2]
      return (
        (platform1Score === undefined ? 999 : platform1Score) -
        (platform2Score === undefined ? 999 : platform2Score)
      )
    },
  )
}

export function getMainPlatformsByPlatformType(
  platforms: string[] = [],
): string[] {
  return platforms.sort((platform1 = 'others', platform2 = 'others') => {
    const platform1Score =
      platformSortScore[getPlatformShortName(parseInt(platform1))]
    const platform2Score =
      platformSortScore[getPlatformShortName(parseInt(platform2))]
    return (
      (platform1Score === undefined ? 999 : platform1Score) -
      (platform2Score === undefined ? 999 : platform2Score)
    )
  })
}

export function sortByPlatformScore<T>(data: T[] = [], key: string): T[] {
  return uniqBy(data, key).sort((prevData, nextData) => {
    const prevDataScore = platformSortScore[prevData[key] || 'others']
    const nextDataScore = platformSortScore[nextData[key] || 'others']
    return (
      (prevDataScore === undefined ? 999 : prevDataScore) -
      (nextDataScore === undefined ? 999 : nextDataScore)
    )
  })
}

export function sortByPlatformTypeScore<T>(data: T[] = [], key: string): T[] {
  return uniqBy(data, key).sort((prevData, nextData) => {
    const prevDataScore =
      platformTypeSortScore[prevData[key] ?? PlatformType.Other]
    const nextDataScore =
      platformTypeSortScore[nextData[key] ?? PlatformType.Other]
    return (
      (prevDataScore === undefined ? 999 : prevDataScore) -
      (nextDataScore === undefined ? 999 : nextDataScore)
    )
  })
}
