import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react'
import { useLocalStorage } from 'react-use'

const observerMap: Record<string, Dispatch<SetStateAction<unknown>>[]> = {}

/**
 * @description Avoid using this function in server-side render contexts
 */
const useLocalStorageSafely: typeof useLocalStorage = (
  key,
  initialValue,
  options,
) => {
  const [localStorageValue, _setLocalStorageValue, removeLocalStorageValue] =
    useLocalStorage(key, initialValue, options)
  const keyRef = useRef(key)

  useEffect(() => {
    if (!observerMap[keyRef.current]) {
      observerMap[keyRef.current] = []
    }
    observerMap[keyRef.current].push(_setLocalStorageValue)

    return () => {
      const index = observerMap[keyRef.current].indexOf(_setLocalStorageValue)
      if (index > -1) {
        observerMap[keyRef.current].splice(index, 1)
      }
    }
  }, [_setLocalStorageValue])

  const setLocalStorageValue: typeof _setLocalStorageValue = useCallback(
    (value) => {
      if (observerMap[keyRef.current]) {
        observerMap[keyRef.current].forEach((observer) => observer(value))
      }
    },
    [],
  )

  return [localStorageValue, setLocalStorageValue, removeLocalStorageValue]
}

export default useLocalStorageSafely
export { observerMap as localStorageObserverMap }
